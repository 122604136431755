 import * as React from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { ServeyStepOne } from './serveySteps/ServeyStepOne';
import { ServeyStepTwo } from './serveySteps/ServeyStepTwo';
import { ServeyStepThree } from './serveySteps/ServeyStepThree';
import { ServeyStepFour } from './serveySteps/ServeyStepFour';
import { ServeyStepFive } from './serveySteps/ServeyStepFive';
import { Get, Post, Serialize } from '../services/http';
import { LinkCss } from '../services/cssService';

export const Lead = () => {
	const [cffResult, setCffResult] = React.useState('');
	const [score, setScore] = React.useState('');
	const { id } = useParams();
	const [serveyCount, setServeyCount] = React.useState(0);
	const [optimaiPopup, setOptimaiPopup] = React.useState(null);
	const [formObject, setFormObject] = React.useState({ firstName: "", lastName: "", phone: "", email: "", mortgage: "", terms: "", campaign: "empty", keyword: "empty" });
	const [validPopup, setValidPopup] = React.useState(false);
	const [upperCalc, setUpperCalc] = React.useState(null);
	const [routh, setRouth] = React.useState(null);
	const [madadStability, setMadadStability] = React.useState(null);
	const [totalRoutes, setTotalRoutes] = React.useState(null);
	const [validationCode, setValidationCode] = React.useState(0);
	const [leadId, setLeadId] = React.useState('');
	const [serveyObject, setServeyObject] = React.useState('');

	const setUrl = () => {
		const restoredServeyObjectString = localStorage.getItem('serveyObject'); 
		const serveyObjectReady = JSON.parse(restoredServeyObjectString);

		let newServeyObject = {
			...formObject,
			...{
				campaign: serveyObjectReady['campaign'],
				keyword: serveyObjectReady['keyword']
			}
		};

		setFormObject(newServeyObject);
	};

	React.useEffect(() => {
		LinkCss("/css/style.css");
		setUrl();
	}, []);

	const calcPMT = (apr, term, loan) => {
		const terms = term * 12;
		const aprs = apr / 1200;
		const amount = (aprs * -loan * (1 + aprs) ** terms) / (1 - (1 + aprs) ** terms);
		return Math.round(amount);
	};

	const restoreServeyObject = () => {
		const restoredServeyObjectString = localStorage.getItem('serveyObject');
		const serveyObjectReady = JSON.parse(restoredServeyObjectString);
		setServeyObject(serveyObjectReady);
	}; 

	const getServeyResult = async () => {
		// let objectToSend = { name: 'vasily', action: '' };
		let newServeyObject = {
			...{
				action: 'get_cff_result_by_id'
			}
		};
		let form_data = new FormData();
		form_data.append('action', 'get_cff_result_by_id');
		const paramsObj = { action: 'get_cff_result_by_id', proposal_id: id.toString() };
		const params = Serialize(paramsObj);
		axios.get('https://optimai.co.il/wp-admin/admin-ajax.php?' + params).then(function (response) {
			console.log('result ', response.data?.data?.id);
			setCffResult(response.data);
			setServeyCount(parseInt(response.data?.data?.id) + 250000);
			const scoreRes = response.data?.result?.ratings * 1.64 - 82;
		});

		/* const result = await Get('https://optimai.co.il/wp-admin/admin-ajax.php', { action: 'calc_mortagage' }); */
	};

	const flipString = (value) => {
		let output = [];

		if (value.length === 1) {
			return value;
		}

		for (let i = value.length; i >= 0; i--) {
			output.push(value[i]);
		}

		return output.join('');
	};

	const mask = (num) => {
		let output = [];

		num = num.toString();

		for (let i = 0; i < num.length; i++) {
			if (i === 0 && num[0] === '0') {
				output.push('');
			} else {
				output.push(num[i]);
			}
		}

		num = output.join('');
		output = [];
		let value = flipString(num);

		for (let i = 0; i < value.length; i++) {
			if (i !== 0 && i % 3 === 0) {
				output.push(',');
			}

			if (i !== value.length) {
				output.push(value[i]);
			}
		}

		value = output.join('');

		return flipString(value);
	};

	const removeCommons = (value) => {
		let output = [];

		for (let i = 0; i < value.length; i++) {
			if (value[i] === ',' || !(value[i] >= '0' && value[i] <= '9')) {
			output.push('');
			} else {
			output.push(value[i]);
			}
		}

		return output.join('');
	}; 

	function validateEmail($email) {
		let emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
		if (emailReg.test($email) && $email !== "") {
			return true;
		}
		return false;
	}

	/* const validetePhone = (value) => {
		if (value === undefined) {
			return false;
		} else if (value.length !== 10) {
			return false;
		} else {
			return true;
		}
	}; */

	function validetePhone($phone) {
		let phoneReg = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;
		if (phoneReg.test($phone) && $phone !== "") {
			return true;
		}
		return false;
	}

	const valideteEmpty = (value) => {
		if (value === undefined) {
			return false;
		} else if (value.length < 2) {
			return false;
		} else {
			return true;
		}
	};

	async function updateImutForLead() {
		const params = Serialize({ leadId: leadId, action: 'update_lead_otp' });

		await axios.get('https://optimai.co.il/wp-admin/admin-ajax.php?' + params).then(function (response) {
			console.log('otpUpdateResult', response);
		}); /* powerlink.co.il */
	}

	async function goToResults() {
		window.location.href = "/results/" + cffResult?.result?.proposal_id;
	}

	async function phoneValid() {
		const element = document.getElementById('phoneValidInput');
		const elementWrap = element.closest(".popup-input-wrapper");
		elementWrap.classList.remove("active");
		const val = element.value;

		console.log('debugNum', val, parseInt(validationCode));

		if (parseInt(val) === parseInt(validationCode)) {
			await updateImutForLead();
			await goToResults(); 
		} else {
			elementWrap.classList.add("active");
		}
	}

	const phoneValidInput = () => {
		const element = document.getElementById('phoneValidInput');
		const elementWrap = element.closest(".popup-input-wrapper");
		elementWrap.classList.remove("active");
	};

	const readInputValue = (identificator) => {
		const formObjectSave = {};
		const element = document.getElementById(identificator);
		const elementWrap = element.closest(".form-input-wrapper");
		const inputValue = element.value;
		formObjectSave[identificator] = inputValue;
		let newFormObject = {
			...formObject,
			...formObjectSave
		};
		setFormObject(newFormObject);

		if (!valideteEmpty(inputValue)) {
			elementWrap.classList.add("notValid");
		} else {
			elementWrap.classList.remove("notValid");
		}

		if (identificator === 'email') {
			if (!validateEmail(inputValue) && valideteEmpty(inputValue)) {
				elementWrap.classList.add("notValid");
				elementWrap.classList.add("currValid");
			} else if (validateEmail(inputValue)) {
				elementWrap.classList.remove("notValid");
				elementWrap.classList.remove("currValid");
			}
		}

		if (identificator === 'phone') {
			if (!validetePhone(inputValue) && valideteEmpty(inputValue)) {
				elementWrap.classList.add("notValid");
				elementWrap.classList.add("currValid");
			} else if (validetePhone(inputValue)) {
				elementWrap.classList.remove("notValid");
				elementWrap.classList.remove("currValid");
			}
		}
	};

	const radioChange = (event) => {
		const element = document.querySelectorAll('.form-radio-wrapper');
		element[0].classList.remove("notValid");

		let value;

		if (event.target.defaultValue === "need") {
			value = 1;
		} else {
			value = 2;
		}

		let newFormObject = {
			...formObject,
			...{
				mortgage: value,
			}
		};
		setFormObject(newFormObject);
	};

	const checkboxChange = (event) => {
		const element = document.querySelectorAll('.form-checkbox-wrapper');
		element[0].classList.remove("notValid");

		let newFormObject = {
			...formObject,
			...{
				terms: event.target.checked
			}
		};
		setFormObject(newFormObject);
	};

	const sendSuccessful = () => {
		const popup = document.querySelectorAll('.form-send-successful');
		popup[0].classList.add("active");

		setTimeout(() => {
			popup[0].classList.remove("active");
		}, 4000);
	};

	const sendForm = () => {
		const inputs = document.querySelectorAll('.form-input-wrapper input');

		for (const input of inputs) {
			const elementWrap = input.closest(".form-input-wrapper");

			if (!valideteEmpty(input.value)) {
				elementWrap.classList.add("notValid");
			}
		}

		if (formObject['mortgage'] === "") {
			const element = document.querySelectorAll('.form-radio-wrapper');
			element[0].classList.add("notValid");
		}

		if (formObject['terms'] === false || formObject['terms'] === "") {
			const element = document.querySelectorAll('.form-checkbox-wrapper');
			element[0].classList.add("notValid");
		}

		if (valideteEmpty(formObject['firstName']) && valideteEmpty(formObject['lastName']) && valideteEmpty(formObject['phone']) && valideteEmpty(formObject['email']) && validateEmail(formObject['email']) && formObject["terms"] === true && formObject['mortgage'] !== "") {
			console.log('send work');
			setValidPopup(true);
			const sendObject = {
				...cffResult.data,
				...formObject,
				encodedCity: serveyObject['city'],
				action: 'save_lead'
			};

			const params = Serialize(sendObject);
			console.log('sendObject', sendObject);

			axios.get('https://optimai.co.il/wp-admin/admin-ajax.php?' + params).then(function (response) {
				console.log('sendResult', response);
				if (response.data.result3 !== undefined) {
					console.log('sendResult result3', response.data.result3.data.Record.pcfsystemfield234);
					setValidationCode(response.data.result3.data.Record.pcfsystemfield234);
				} else {
					console.log('sendResult result2', response.data.result2.data.Record.pcfsystemfield87, response.data.result2.data.Record.customobject1001id);
					setValidationCode(response.data.result2.data.Record.pcfsystemfield87);
					setLeadId(response.data.result2.data.Record.customobject1001id);
				}  

				/* for (const input of inputs) {
					input.value = '';
				} */
				// sendSuccessful();
			}); /* powerlink.co.il */
		}
	};

	const closeValidPopup = () => {
		setValidPopup(false);
		let element = document.getElementById('phoneValidInput');
		element.value = "";
	};

	React.useEffect(() => {
		getServeyResult();
		restoreServeyObject();
	}, []);

	(function (d) { 
		let s = d.createElement("script"); 
		s.setAttribute("data-account", "es8YPEWAQK"); 
		s.setAttribute("src", "https://cdn.userway.org/widget.js"); 
		(d.body || d.head).appendChild(s); 
	}(document));

	return (
		<>
			<Helmet>
				<title>מחשבון משכנתא חכם - אופטימאי</title>
			</Helmet>

			<noscript>Please ensure Javascript is enabled for purposes of <a href="https://userway.org">website accessibility</a></noscript>
			<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9988069504628480" crossorigin="anonymous"></script>
			<body class="loaded">

				<div id="call-us-popup-modal-container" className={optimaiPopup ? 'optimai' : 'optimai none'}>
					<div id="call-us-modal-wrapper" class="call-us-modal-wrapper">
						<div class="contact-us-popup-wrapper">
							<div class="close-contact-us-popup" onClick={() => setOptimaiPopup(false)}><div class="close-popup-orange-wrapper"><div class="close-popup-white-wrapper"><img src="/img/custom-close-popup-icon.png" alt="" /></div></div></div>
							<div class="contact-us-popup-image-wrapper inline-block">
								<img src="/img/poup-phone-image.png" alt="" />
							</div>
							<div class="contact-us-popup-info-wrapper inline-block">
								<div class="contact-us-popup-ornage-bubble-container">
									<img src="/img/orange-bubble.png" alt="" />
									<div class="contact-us-popup-main-title">אני אופטימאי</div>
									<div class="contact-us-popup-secondary-title">מחשבון משכנתא חכם</div>
								</div>
								<div class="contact-us-popup-text">
									בעוד כמה רגעים אגלה לך<br />כל מה שאתה צריך לדעת<br />על המשכנתא שלך.
								</div>
								<div class="contact-us-popup-blue-button">משהו לא ברור<br />התקשרו <a href="tel:0508121242">050-8121242</a></div>
							</div>
							<div class="popup-button" onClick={() => setOptimaiPopup(false)}>הבנתי!</div>
						</div>
					</div>
				</div>

				<div className={validPopup ? 'popup-wrapper' : 'popup-wrapper none'}>
					<div class="popup">
						<div class="popup-close" onClick={() => closeValidPopup()}></div>
						<div class="popup-title">הכנס קוד אמות שנשלח לך בSMS</div>
						<div class="popup-input-wrapper inline-block">
							<div class="popup-input-warning">הקוד לא נכון!</div>
							<input class="popup-input number" onChange={() => phoneValidInput()} id="phoneValidInput" type="number" inputmode="numeric" />
						</div>
						<div class="popup-button-basic inline-block" onClick={() => phoneValid()}>אימות</div>
					</div>
				</div>

				{/* BEGIN main wrapper */}
				<div class="main-wrapper">

					{/* BEGIN HEADER */}
					<div class="header-wrapper">
						<header class="header">
							<div class="container header__container">
								<div class="header__logo">
									<a class=" logo"><img src="/img/logo2.png" alt="" onClick={() => setOptimaiPopup(true)} /></a>
									<a href="tel:0508121242" class="header-phone">
										050-812-1242
										<img src="/img/phone-icon.png" alt="" />
									</a>
								</div>
								<div class="header__icons h-icons">
									<div class="h-icons__item">
										<div class="h-icons__img">
											<img src="/img/homeicon1.png" alt="" />
										</div>
										<div class="h-icons__title">חישוב מספר</div>
										<div class="h-icons__subtitle">{mask(serveyCount)}</div>
									</div>
									<div class="h-icons__item">
										<div class="h-icons__img">
											<img src="/img/homeicon2.png" alt="" />
										</div>
										<div class="h-icons__title">משכנתא בסך</div>
										<div class="h-icons__subtitle">{cffResult ? mask(cffResult?.data?.mortgageAmount) : ''}</div>
									</div>
									<div class="h-icons__item">
										<div class="h-icons__img">
											<img src="/img/homeicon3.png" alt="" />
										</div>
										<div class="h-icons__title">לנכס בשווי</div>
										<div class="h-icons__subtitle">{cffResult ? mask(cffResult?.data?.propertyValue) : ''}</div>
									</div>
								</div>
								<div class="header__edit">
									<a href="/" class="edit-button">
										<img src="/img/edit.png" alt="" />
										<span>ערוך <br />נתונים</span>
									</a>
								</div>
							</div>
						</header>
					</div>

					{/* HEADER EOF   */}

					{/* BEGIN MAIN */}
					<main class="main">
						<section class="form-section section">
							<div class="form-slider-wrapper">
								<div class="form-slider">
									<div class="form-slider-text">המשכנתא שחישבת<span>בדף הבא</span></div>
									<input type="range" min="2" max="60" id="calc-mashkanta" class="form-slider-input" disabled />
								</div>
							</div>
							<div class="form-wrapper">
								<div class="form-send-successful">
									<span>פרטיך נשלחו בהצלחה<br />אנחנו נחזור אליך</span>
								</div>
								<div class="form-title">
									<strong>השלם את הנתונים</strong>
									נציג מטעם אופטימאי ייעוץ משכנתא חכם יחזור אליך
								</div>
								<div class="form-inputs-wrapper">
									<div class="form-input-wrapper inline-block">
										<div class="from-input-warning">חובה לסמן!</div>
										<input onChange={() => readInputValue('firstName')} type="text" placeholder="שם פרטי" id="firstName" />
									</div>
									<div class="form-input-wrapper inline-block">
										<div class="from-input-warning">חובה לסמן!</div>
										<input onChange={() => readInputValue('lastName')} type="text" placeholder="שם משפחה" id="lastName" />
									</div>
									<div class="form-input-wrapper inline-block">
										<div class="from-input-warning">חובה לסמן!</div>
										<div class="from-input-warning currValid">הזן מספר טלפון חוקי!</div>
										<input onChange={() => readInputValue('phone')} type="text" placeholder="טלפון נייד" id="phone" inputmode="numeric" />
									</div>
									<div class="form-input-wrapper inline-block">
										<div class="from-input-warning">חובה לסמן!</div>
										<div class="from-input-warning currValid">הזן כתובת אמייל חוקית!</div>
										<input onChange={() => readInputValue('email')} type="text" placeholder="דואר אלקטרוני" id="email" />
									</div>
								</div>
								<div class="form-questions-wrapper">
									<div class="form-questions-item inline-block">
										<div class="form-tip">
											<img class="inline-block" src="/img/tip-icon.png" alt="" />
											<div class="form-tip-text inline-block">
												לאיזה צורך השתמשת במחשבון?
												<span>טיפ: אם בכוונתך לקחת משכנתא ב 24 החודשים הקרובים, בחר "צריך משכנתא"</span>
											</div>
										</div>
									</div>
									<div class="form-questions-item inline-block send">
										<div class="form-radio-wrapper inline-block">
											<div class="from-input-warning">חובה לסמן!</div>
											<input class="inline-block" onChange={(event) => radioChange(event)} type="radio" name="mortgage" value="need" id="mortagageNeed" />
											<label class="inline-block" for="need">צריך משכנתא</label><br />
											<input class="inline-block" onChange={(event) => radioChange(event)} type="radio" name="mortgage" value="interested" id="mortagageInterested" />
											<label class="inline-block" for="interested">רק מתעניין</label>
										</div>
										<a class="form-send-button inline-block" id="form-send-button" onClick={() => sendForm()}>שלח</a>
									</div>
									<div class="form-checkbox-wrapper">
										<div class="from-input-warning">חובה לסמן!</div>
										<input class="inline-block" type="checkbox" value="need" onChange={(event) => checkboxChange(event)} />
										<label class="inline-block" for="need">אני מאשר את תנאי השימוש באתר. ייתכן שנציג מטעמנו ייצור איתך קשר.*</label><br />
									</div>
								</div>
							</div>
						</section>
					</main>
					{/* MAIN EOF */}

					{/* BEGIN FOOTER */}
					<footer class="footer">
						<div class="container">
							<p>
								כל הזכויות שמורות לאופטימאי בע"מ | הגלישה באתר מהווה הסכמה <strong><a href="#" target="_blank">לתנאי התקנון</a></strong>
							</p>
						</div>
					</footer>
					{/* FOOTER EOF */}

				</div>
				{/* main wrapper EOF */}

			</body>

		</>
	);
};
