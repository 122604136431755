import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { ServeyStepOne } from './serveySteps/ServeyStepOne';
import { ServeyStepTwo } from './serveySteps/ServeyStepTwo';
import { ServeyStepThree } from './serveySteps/ServeyStepThree';
import { ServeyStepFour } from './serveySteps/ServeyStepFour';
import { ServeyStepFive } from './serveySteps/ServeyStepFive';
import { LinkCss } from '../services/cssService';

// import '../css/style.css';

export const Reports = () => {
  const [currentStep, setCurrentStep] = React.useState(1);
  const [serveyObject, setServeyObject] = React.useState({ mortgageGoal: "", mortgageAmount: 0, propertyValue: 0, loanUrgency: 0, propertyType: 0, propertyAddress: 0, city: 0, gpsX: 0, gpsY: 0, dob: 0, kids: 0, osh: 0, checks: 0, lates: 0, loanRepayments: 0, monthlyIncome: 0, seniority: 0 });
  const [optimaiPopup, setOptimaiPopup] = React.useState(null);

  React.useEffect(() => {
		LinkCss("/css/style.css");
	}, []);

  const initAccordion = () => {
    const boxes = document.querySelectorAll('.spoller__head');

    for (const box of boxes) {
      box.addEventListener('click', function handleClick() {
        const parents = document.querySelectorAll('.spoller__item');
        const parent = box.closest(".spoller__item");

        if (parent.className === 'spoller__item active') {
          for (let i = 0; i < parents.length; i++) {
            parents[i].classList.remove('active');
          }
        } else {
          for (let i = 0; i < parents.length; i++) {
            parents[i].classList.remove('active');
          }
          parent.classList.add('active');
        }
      });
    }
  };

  const flipString = (value) => {
    let output = [];
    if (value.length === 1) {
      return value;
    }
    for (let i = value.length; i >= 0; i--) {
      output.push(value[i]);
    }

    return output.join('');
  };

  const mask = (num) => {
    let output = [];

    num = num.toString();

    for (let i = 0; i < num.length; i++) {
      if (i === 0 && num[0] === '0') {
        output.push('');
      } else {
        output.push(num[i]);
      }
    }

    num = output.join('');
    output = [];
    let value = flipString(num);

    for (let i = 0; i < value.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        output.push(',');
      }

      if (i !== value.length) {
        output.push(value[i]);
      }
    }

    value = output.join('');

    return flipString(value);
  };

  React.useEffect(() => {
    initAccordion();
    document.querySelector('video').play();
  }, []);

  React.useEffect(() => {
    console.log("optimaiPopup", optimaiPopup);
  }, [optimaiPopup]);

  (function (d) { 
		let s = d.createElement("script"); 
		s.setAttribute("data-account", "es8YPEWAQK"); 
		s.setAttribute("src", "https://cdn.userway.org/widget.js"); 
		(d.body || d.head).appendChild(s); 
	}(document));

  return (
    <>
      <Helmet>
        <title>מחשבון משכנתא חכם - אופטימאי</title>
      </Helmet>

      <noscript>Please ensure Javascript is enabled for purposes of <a href="https://userway.org">website accessibility</a></noscript>

      <div class="main-wrapper">

        <div id="call-us-popup-modal-container" className={optimaiPopup ? 'optimai' : 'optimai none'}>
          <div id="call-us-modal-wrapper" class="call-us-modal-wrapper">
            <div class="contact-us-popup-wrapper">
              <div class="close-contact-us-popup" onClick={() => setOptimaiPopup(false)}><div class="close-popup-orange-wrapper"><div class="close-popup-white-wrapper"><img src="/img/custom-close-popup-icon.png" alt="" /></div></div></div>
              <div class="contact-us-popup-image-wrapper inline-block">
                <img src="/img/poup-phone-image.png" alt="" />
              </div>
              <div class="contact-us-popup-info-wrapper inline-block">
                <div class="contact-us-popup-ornage-bubble-container">
                  <img src="/img/orange-bubble.png" alt="" />
                  <div class="contact-us-popup-main-title">אני אופטימאי</div>
                  <div class="contact-us-popup-secondary-title">מחשבון משכנתא חכם</div>
                </div>
                <div class="contact-us-popup-text">
                  בעוד כמה רגעים אגלה לך<br />כל מה שאתה צריך לדעת<br />על המשכנתא שלך.
                </div>
                <div class="contact-us-popup-blue-button">משהו לא ברור<br />התקשרו <a href="tel:0508121242">050-8121242</a></div>
              </div>
              <div class="popup-button" onClick={() => setOptimaiPopup(false)}>הבנתי!</div> 
            </div>
          </div>
        </div>

        {/* BEGIN HEADER */}
        {currentStep !== 1 ? (
          <header class="header">
            <div class="container header__container">
              <div class="header__logo">
                <a class=" logo"><img src="img/logo2.png" alt="" onClick={() => setOptimaiPopup(true)} /></a>
              </div>
              <div class="header__icons h-icons">
                <div class="h-icons__item">
                  <div class="h-icons__img">
                    <img src="img/homeicon1.png" alt="" />
                  </div>
                  <div class="h-icons__title">מטרת המשכנתא</div>
                  <div class="h-icons__subtitle">{serveyObject['mortgageGoal'].name}</div>
                </div>
                <div class="h-icons__item">
                  <div class="h-icons__img">
                    <img src="img/homeicon2.png" alt="" />
                  </div>
                  <div class="h-icons__title">משכנתא בסך</div>
                  <div class="h-icons__subtitle">{mask(serveyObject['mortgageAmount'])}</div>
                </div>
                <div class="h-icons__item">
                  <div class="h-icons__img">
                    <img src="img/homeicon3.png" alt="" />
                  </div>
                  <div class="h-icons__title">לנכס בשווי</div>
                  <div class="h-icons__subtitle">{mask(serveyObject['propertyValue'])}</div>
                </div>
              </div>
              <div class="header__edit">
                <a href="" class="edit-button">
                  <img src="img/edit.png" alt="" />
                  <span>ערוך <br />נתונים</span>
                </a>
              </div>
            </div>
          </header>
        ) : ''}
        {/* HEADER EOF   */}

        {/* BEGIN MAIN */}
        <main class="main">
          <div class="home-page">
            {currentStep === 1 ? (<ServeyStepOne currentStep={currentStep} setCurrentStep={setCurrentStep} serveyObject={serveyObject} setServeyObject={setServeyObject} setOptimaiPopup={setOptimaiPopup} />) : ''}
            {currentStep === 2 ? (<ServeyStepTwo currentStep={currentStep} setCurrentStep={setCurrentStep} serveyObject={serveyObject} setServeyObject={setServeyObject} />) : ''}
            {currentStep === 3 ? (<ServeyStepThree currentStep={currentStep} setCurrentStep={setCurrentStep} serveyObject={serveyObject} setServeyObject={setServeyObject} />) : ''}
            {currentStep === 4 ? (<ServeyStepFour currentStep={currentStep} setCurrentStep={setCurrentStep} serveyObject={serveyObject} setServeyObject={setServeyObject} />) : ''}
            {currentStep === 5 ? (<ServeyStepFive currentStep={currentStep} setCurrentStep={setCurrentStep} serveyObject={serveyObject} setServeyObject={setServeyObject} />) : ''}
          </div>
          <section class="how-it-works-section">
            <div class="container">
              <div class="how-it-works">
                <div class="how-it-works__img">
                  <img src="img/Asset-3@4x-1.png" alt="" />
                </div>
                <h2 class="how-it-works__title">ככה זה עובד</h2>
                <div class="how-it-works__row">
                  <div class="how-it-works__item hiw-item">
                    <div class="hiw-item__img">
                      <img src="img/Untitled-1-01-1.png" alt="" />
                    </div>
                    <div class="hiw-item__title">מגיעים <strong>חכמים</strong> יותר, <strong>בטוחים</strong> יותר <strong>ומוכנים יותר.</strong></div>

                  </div>
                  <div class="how-it-works__item hiw-item">
                    <div class="hiw-item__img">
                      <img src="img/Untitled-1-02-1.png" alt="" />
                    </div>
                    <div class="hiw-item__title">מקבלים את <strong>הריביות וההחזר החודשי </strong>המתאימים למשכנתא שלכם.</div>

                  </div>
                  <div class="how-it-works__item hiw-item">
                    <div class="hiw-item__img">
                      <img src="img/Untitled-1-03-1.png" alt="" />
                    </div>
                    <div class="hiw-item__title">מכניסים את <strong>סכום המשכנתא</strong> המבוקש ואת. <strong>שווי הנכס</strong>.</div>

                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="video">
            <video class="elementor-video" src="https://optimai.codersuccess.com/wp-content/uploads/2021/11/Build-Scenes-All-1-small-8.m4v" autoplay loop controls="" muted="muted" playsinline="" controlslist="nodownload" track=""></video>
          </div>
          <section class="faq-section">
            <div class="container">
              <div class="faq">
                <div class="faq__img">
                  <img src="img/Asset-3@4x-1.png" alt="" />
                </div>
                <h2 class="faq__title">שאלות ותשובות נפוצות</h2>
                <div class="spoller">
                  <div class="spoller__item active">
                    <div class="spoller__head">
                      <div class="spoller__title">מתלבטים אם לקחת משכנתא?</div>
                    </div>
                    <div class="spoller__body">
                      <p>אופטימאי מבהיר לכם את התמונה. <br />
                        מאפשר לדעת מראש את המשכנתא שבאפשרותכם לקבל, את התנאים, הריביות וההחזר החודשי הצפויים לכם ומידע רב ערך נוסף שיסייע לכם בקבלת ההחלטה. <br />
                        כל זאת, באופן מיידי, בלי צורך לפנות לבנק.
                      </p>
                    </div>
                  </div>
                  <div class="spoller__item">
                    <div class="spoller__head">
                      <div class="spoller__title">החלטתם לקחת משכנתא?</div>
                    </div>
                    <div class="spoller__body">
                      <p>מחשבון המשכנתאות החכם של אופטימאי מאפשר לכם לדעת מראש וכבר בתחילת הדרך את תנאי המשכנתא שבאפשרותכם לקבל ולכלכל את צעדיכם בתבונה. <br /> המידע שאופטימאי מספק, מאפשר לך להבין אילו תנאים וריביות באפשרותך להשיג ומשפר את מעמדך במשא ומתן מול הבנקים., חוסך זמן ומקצר את תהליך קבלת המשכנתא.</p>
                    </div>
                  </div>
                  <div class="spoller__item">
                    <div class="spoller__head">
                      <div class="spoller__title">קיבלתם הצעה למשכנתא? </div>
                    </div>
                    <div class="spoller__body">
                      <p>אופטימאי מאפשר לכם להבין עד כמה היא טובה.<br />באפשרותכם להשוות את ההצעה שקיבלתם לתחזית הריביות של אופטימאי ולהשוות נתוני מפתח כמו סה''כ ריבית והחזר לשקל.</p>
                    </div>
                  </div>
                  <div class="spoller__item">
                    <div class="spoller__head">
                      <div class="spoller__title">האם השימוש במחשבון כרוך בתשלום? </div>
                    </div>
                    <div class="spoller__body">
                      <p>לא.<br />השימוש במחשבון חופשי ואינו כרוך בתשלום.<br />ניתן לחשב יותר ממשכנתא אחת.</p>
                    </div>
                  </div>
                  <div class="spoller__item">
                    <div class="spoller__head">
                      <div class="spoller__title">מחשבון משכנתא חכם <span class="show-desktop">של אופטימאי</span> - מה זה?</div>
                    </div>
                    <div class="spoller__body">
                      <p>הרשת שופעת מחשבוני משכנתא, רובם ככולם מבקשים מכם לשער את הריבית שתקבלו או מאפשרים חישוב על בסיס שיעור ריבית שאינו ניתן ובד"כ לא ידוע.<br />המשמעות היא שהתוצאות המתקבלות במחשבון הן לא יותר מהערכה גסה שאינה מאפשרת להבין באמת איפה אתם עומדים.<br />מחשבון משכנתא חכם מביא בשורה חדשה – למחשבון המשכנתא של אופטימאי יכולת לחזות בדיוק רב את הריביות הצפויות בהתאם לנתוני המשכנתא והלווה.<br />כך, מחשבון המשכנתא החכם של אופטימאי מספק תוצאות מדויקות שמאפשרות לכם לקבל תמונה ברורה ומדויקת.</p>
                    </div>
                  </div>
                  <div class="spoller__item">
                    <div class="spoller__head">
                      <div class="spoller__title">איך עובד המחשבון? </div>
                    </div>
                    <div class="spoller__body">
                      <p>אופטימאי עושה שימוש במאגר נתוני משכנתאות גדול וטכנולוגיה מתקדמת שמאפשרת לדמות את תהליך קביעת הריביות למשכנתא שמבצעים הבנקים. המחשבון מדמה את תהליך קביעת הריביות בכל אחד מהבנקים השונים ומציג את התוצאה הטובה ביותר שהתקבלה.</p>
                    </div>
                  </div>
                  <div class="spoller__item">
                    <div class="spoller__head">
                      <div class="spoller__title">מה משפיע על ריביות המשכנתא? </div>
                    </div>
                    <div class="spoller__body">
                      <p>המרכיב המרכזי הוא "סיכון". בכל הלוואת משכנתא, גלום סיכון מסוים לכך שהיא לא תפרע כסדרה. בעוד שרמת סיכון נמוכה מאפשרת להעמיד את ההלוואה תמורת ריבית נמוכה יחסית, רמת סיכון גבוהה פירושה ריבית גבוהה שמובילה למחיר גבוה.</p>
                    </div>
                  </div>
                  <div class="spoller__item">
                    <div class="spoller__head">
                      <div class="spoller__title">מדוע ריביות המשכנתא שונות מבנק לבנק? </div>
                    </div>
                    <div class="spoller__body">
                      <p>במדינות שונות בעולם (קנדה ואוסטרליה לדוגמא) הנוסחה שלפיה מחושב הסיכון נקבעת ע"י הבנק המרכזי והריבית על המשכנתאות מפוקחת וקבועה בחוק, בדומה למחיר מוצרי יסוד בישראל.<br />במדינות אלה, ריבית המשכנתא אחידה וזהה בכל הבנקים.<br />בישראל, שוק המשכנתאות לדיור פתוח לתחרות. כל בנק מעריך את רמת הסיכון של כל משכנתא בעצמו וכמיטב הבנתו, כך שריביות המשכנתא שונות מבנק לבנק.<br />כשמוסיפים על כך גם השפעות חיצוניות כמו שיקולים עסקיים, שיווקיים ומסחריים, פערי הריביות בין הבנקים גדלים אפילו יותר.</p>
                    </div>
                  </div>
                  <div class="spoller__item">
                    <div class="spoller__head">
                      <div class="spoller__title">האם אופטימאי קשור לבנקים? </div>
                    </div>
                    <div class="spoller__body">
                      <p>לא.<br />אופטימאי הוא מחשבון משכנתא עצמאי, אובייקטיבי לחלוטין ואין לו כל קשר שהוא לבנק או לגוף פיננסי כלשהו.</p>
                    </div>
                  </div>
                  <div class="spoller__item">
                    <div class="spoller__head">
                      <div class="spoller__title">מה צריך לזכור כשמשווים משכנתאות וריביות?</div>
                    </div>
                    <div class="spoller__body">
                      <p>חשוב לזכור ולהבין שסכום הריבית הכולל של המשכנתא, מושפע ממשך תקופת המשכנתא (בדומה לדמי שכירות לתקופה ארוכה יותר).<br />כאשר משווים בין משכנתאות באמצעות סכום מוחלט, כלומר סך הריבית המשולמת, יש לוודא שמדובר במשכנתאות לתקופות זהות.<br />כאשר מבקשים להשוות בין משכנתאות לתקופות שונות, יש להשוות לפי ריבית ממוצעת משוקללת (או לפי ריביות).<br />אפשרות אחרת, היא להמיר את אחת המשכנתאות לתקופה דומה.<br />לצורך כך, ניתן להיעזר בכלי היוון ומחשבון השוואת משכנתאות.</p>
                    </div>
                  </div>
                  <div class="spoller__item">
                    <div class="spoller__head">
                      <div class="spoller__title">עלויות נוספות לקחת בחשבון</div>
                    </div>
                    <div class="spoller__body">
                      <p>בנוסף הבנק מבקש להעריך את שווי הנכס באמצעות שמאי עלויות השמאי נעות בטווח 500-1200 ש"ח. כמו כן לקיחת משכנתא כרוכה בפתיחת תיק בבנק בעלות של 1000-3000 ש"ח תלוי בבנק ובהנחה שקיבלתם על פתיחת התיק.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* MAIN EOF   */}

        {/* BEGIN FOOTER */}
        <footer class="footer">
          <div class="container">
            <p>
              כל הזכויות שמורות לאופטימאי בע"מ | הגלישה באתר מהווה הסכמה <strong><a href="#" target="_blank">לתנאי התקנון</a></strong>
            </p>
          </div>
        </footer>
        {/* FOOTER EOF   */}

      </div>
      {/* main wrapper EOF   */}
    </>
  );
};
