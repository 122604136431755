import { useStoreState } from 'easy-peasy';
import { Box } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/progress.css';

export const ProgressSpinner = () => {
    const isProgress = useStoreState((state) => state.isProgress);

    return isProgress ? (
        <Box sx={{ display: 'flex' }} className="progress-wrapper">
          <CircularProgress className="progress-spinner" />
        </Box>  
    ) : '';
};
