import * as React from 'react';
import Lottie from 'react-lottie';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Get, Post, Serialize } from '../services/http';

import buttonData from '../animations/source/calculator-button.json';

import { LinkCss } from '../services/cssService';
import { Header } from './header';
import { Footer } from './footer';

let interval;

export const Services = () => {
	let popupTimeOut = 180000;
	const [openVideoPopup, setOpenVideoPopup] = React.useState(false);
	const [videoLink, setVideoLink] = React.useState();
	const [phonePopup, setPhonePopup] = React.useState(null);
	const [currSlide, setCurrSlide] = React.useState(3);
	const [slideSide, setSlideSide] = React.useState('');
	let intervals = [];
	let currSLideVar = 3;
	const sliderItems = [
		{ link: "Rn9qNfdbilk", img: "computer-alon.png" },
		{ link: "9z-eJW_hLN0", img: "computer-ben.jpg" },
		{ link: "U7pTkmUpbkU", img: "computer-dv.jpg" },
		{ link: "HX_p5eoEyp0", img: "computer-gadi.png" },
		{ link: "2yLC2XKYu1M", img: "computer-moshe.jpg" }
	];
	const sliderPoints = [
		{ index: 1 },
		{ index: 2 },
		{ index: 3 },
		{ index: 4 },
		{ index: 5 }
	];
	const SliderNum = (num) => {
		if (num === (sliderItems.length + 2)) {
			num = 2;
		}

		if (num === (sliderItems.length + 1)) {
			num = 1;
		}

		if (num === 0) {
			num = sliderItems.length;
		}

		if (num === -1) {
			num = sliderItems.length - 1;
		}

		return num;
	};

	const SliderNextAuto = () => {
		let itemNum = currSLideVar;

		itemNum--;

		setSlideSide('left');
		setCurrSlide(SliderNum(itemNum));
		currSLideVar = SliderNum(itemNum);
	};

	const startAnimation = () => {
		clearInterval(interval);
		interval = setInterval(SliderNextAuto, 3000);
	};

	const SliderNext = () => {
		clearInterval(interval);
		let itemNum = currSlide;

		itemNum--;

		setSlideSide('left');
		setCurrSlide(SliderNum(itemNum));
		currSLideVar = SliderNum(itemNum);
		interval = setTimeout(startAnimation, 10000);
	};

	const SliderPrev = () => {
		clearInterval(interval);
		let itemNum = currSlide;

		itemNum++;

		setSlideSide('right');
		setCurrSlide(SliderNum(itemNum));
		currSLideVar = SliderNum(itemNum);
		interval = setTimeout(startAnimation, 10000);
	};

	const SetItemNum = (num) => {
		if (num > currSlide) {
			setSlideSide('right');
		} else if (num < currSlide) {
			setSlideSide('left');
		}

		setCurrSlide(num);
	};
	React.useEffect(() => {
		let slideItems = document.querySelectorAll('.gallery-slider__item');

		for (let i = 0; i < slideItems.length; i++) {
			slideItems[i].classList.remove('active');
			slideItems[i].classList.remove('left');
			slideItems[i].classList.remove('right');
			slideItems[i].classList.remove('nowSlide');
			slideItems[i].classList.remove('prevSlide');
			slideItems[i].classList.remove('nextSlide');
			slideItems[i].classList.remove('twoPrevSlide');
			slideItems[i].classList.remove('twoNextSlide');
		}

		let twoNextSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide + 2));
		let nextSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide + 1));
		let nowSlide = document.getElementById('gallery-slider__item-' + currSlide);
		let prevSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide - 1));
		let twoPrevSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide - 2));

		nowSlide.classList.add('nowSlide');
		prevSlide.classList.add('prevSlide');
		nextSlide.classList.add('nextSlide');
		twoPrevSlide.classList.add('twoPrevSlide');
		twoNextSlide.classList.add('twoNextSlide');

		if (slideSide === 'left') {
			nowSlide.classList.add('left');
			prevSlide.classList.add('left');
			nextSlide.classList.add('left');
			twoPrevSlide.classList.add('left');
			twoNextSlide.classList.add('left');
		} else {
			nowSlide.classList.add('right');
			prevSlide.classList.add('right');
			nextSlide.classList.add('right');
			twoPrevSlide.classList.add('right');
			twoNextSlide.classList.add('right');
		}

		setTimeout(() => {
			nowSlide.classList.add('active');
			prevSlide.classList.add('active');
			nextSlide.classList.add('active');
			twoPrevSlide.classList.add('active');
			twoNextSlide.classList.add('active');
		}, 100);
	}, [currSlide]);

	React.useEffect(() => {
		clearInterval(interval);
	}, []);
	React.useEffect(() => {
		LinkCss("/css/homepage-style.css");
	}, []);

	const openHowVideo = () => {
		let popup = document.getElementById("video-popup");
		let video = document.getElementById("optimai-video");

		video.play();
		popup.classList.add("active");
	};

	const closeVideoPopup = () => {
		let popup = document.getElementById("video-popup");
		let video = document.getElementById("optimai-video");

		video.pause();
		video.currentTime = 0;
		popup.classList.remove("active");
	};

	const openTab = (tabId) => {
		let clickedTab = document.getElementById(tabId);
		let tabs = document.querySelectorAll(".how-it-works-home-tabs__body.swiper");
		let tabstitles = document.querySelectorAll(".how-it-works-home-tabs__title");
		let tabTitle = document.getElementById(tabId + "-title");

		for (const tab of tabs) {
			tab.classList.remove("active");
		}

		clickedTab.classList.add("active");

		for (const tabstitle of tabstitles) {
			tabstitle.classList.remove("_tab-active");
		}

		tabTitle.classList.add("_tab-active");
	};

	const openVideo = (link) => {
		console.log("clicked");
		setVideoLink(link);
		setOpenVideoPopup(true);
	};

	const closeVideo = () => {
		console.log("clicked");
		setOpenVideoPopup(false);
	};

	const initAccordion = () => {
		const boxes = document.querySelectorAll('.spoller__head');

		console.log('boxes', boxes);

		for (const box of boxes) {
			box.addEventListener('click', function handleClick() {
				const parents = document.querySelectorAll('.spoller__item');
				const parent = box.closest(".spoller__item");

				if (parent.className === 'spoller__item active') {
					for (let i = 0; i < parents.length; i++) {
						parents[i].classList.remove('active');
					}
				} else {
					for (let i = 0; i < parents.length; i++) {
						parents[i].classList.remove('active');
					}
					parent.classList.add('active');
				}
			});
		}
	};

	React.useEffect(() => {
		initAccordion();
	}, []);

	React.useEffect(() => {
		setTimeout(() => {
			setPhonePopup(true);
		}, popupTimeOut);
	}, []);

	const closePhonePopup = () => {
		setPhonePopup(false);
		setTimeout(() => {
			setPhonePopup(true);
		}, popupTimeOut);
	};

	React.useEffect(() => {
		startAnimation();
	}, []);

	return (
		<>
			<head>
				<title>Home</title>
				<meta charset="UTF-8" />
				<meta name="format-detection" content="telephone=no" />
				<link rel="shortcut icon" href="favicon.ico" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="description" content="" />
				<meta name="keywords" content="" />
			</head>

			<body>

				{openVideoPopup === true ? (
					<div id="popup-modal-container">
						<div id="modal-wrapper" class="modal-wrapper" onClick={() => closeVideo()}>
							<div class="close-popup">X</div>
							<iframe width="80%" height="630" src={'https://www.youtube.com/embed/' + videoLink + '?rel=0'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
					</div>
				) : ''}

				<div id="call-us-popup-modal-container" className={phonePopup ? '' : 'none'}>
					<div id="call-us-modal-wrapper" class="call-us-modal-wrapper">
						<div class="contact-us-popup-wrapper">
							<div class="close-contact-us-popup" onClick={() => closePhonePopup()}><div class="close-popup-orange-wrapper"><div class="close-popup-white-wrapper"><img src="/img/custom-close-popup-icon.png" alt="" /></div></div></div>
							<div class="contact-us-popup-image-wrapper inline-block">
								<img src="/img/poup-phone-image.png" alt="" />
							</div>
							<div class="contact-us-popup-info-wrapper inline-block">
								<div class="contact-us-popup-ornage-bubble-container">
									<img src="/img/orange-bubble.png" alt="" />
									<div class="contact-us-popup-main-title">אני אופטימאי</div>
									<div class="contact-us-popup-secondary-title">מחשבון משכנתא חכם</div>
								</div>
								<div class="contact-us-popup-text">
									רוצה לחסוך במשכנתא?
									<br />
									לייעוץ משכנתא חכם
									<br />
									התקשר/י עכשיו
								</div>
								<div class="contact-us-popup-blue-button"> <div>לחצו על המספר</div> <a href="tel:0508121242">050-812-1242</a></div>
							</div>
						</div>
					</div>
				</div>

				<div class="wrapper">
					<Header />
					<main class="home">
						<section class="services services-title">
							<div class="services__container services__container_big">
								<div class="section__title">
									<h2>לאופטימאי יש 4 מסלולים לבחירתכם</h2>
									<h3>מגוון מוצרים וחבילות לכל שלב במשכנתא שיחסכו לכם כמויות של כסף</h3>
								</div>
							</div>
						</section>

						<article style={{ fontSize: "0.625rem" }}>
							<section class="section services-section">
								<div class="container">
									<div class="services">
										<div class="services__item">
											<div class="services__head">
												<div class="services__icon">
													<img src="/img/price-phone-image-desktop.png" alt="" />
												</div>
												<div class="services__title"><strong>ייעוץ</strong> ראשוני</div>	 
												<br></br>
												<div class="services__txt">פגישת זום בת כ 30 דקות. עם יועץ מומחה לניתוח ותכנון פיננסי.</div>
											</div>
											<div class="services__body">
												<ul class="services__list">
													<li> תכנון והגדרת תקציב </li>
													<li> סימולציית משכנתא </li>
													<li> תחזית ריביות </li>
													<li> המלצות לפעולות מקדימות </li>
												</ul>
											</div>
											<div class="services__foot">
											<div class="services__price">מחיר 250 ₪</div>
												<div class="services__txt">כולל מע"מ</div>
												<div class="services__btn">
													<a href="https://secure.cardcom.solutions/e/U74R" target="_blank" rel="noopener noreferrer" class="btn btn--sm"><span class="btn__inner">לרכישה</span></a>
												</div>
											</div>
										</div>
										<div class="services__item">
											<div class="services__head">
												<div class="services__icon">
													<img src="/img/price-paper-desktop.png" alt="" />
												</div>
												<div class="services__title"><strong>תכנון</strong> משכנתא </div>
												<br></br>
												<div class="services__txt">פגישת זום בת כשעה. עם מומחה תכנון משכנתא.</div>
											</div>
											<div class="services__body">
												<ul class="services__list">
													<li> תכנון תמהיל </li>
													<li> תחזית ריביות </li>
													<li> דו"ח משכנתא </li>
													<li> הכנה למכרז ריביות </li>
													<li> המלצות לפעולות נדרשות</li>
												</ul>
											</div>
											<div class="services__foot">
												<div class="services__price">מחיר 1000 ₪</div>
												<div class="services__txt">כולל מע"מ</div>
												<div class="services__btn">
													<a href="https://secure.cardcom.solutions/e/Uvrr" target="_blank" rel="noopener noreferrer" class="btn btn--sm"><span class="btn__inner">לרכישה</span></a>
												</div>
											</div>
										</div>
										<div class="services__item">
											<div class="services__head">
												<div class="services__icon">
													<img src="/img/price-houses-desktop.png" alt="" />
												</div>
												<div class="services__title"> מכרז ריביות <strong>חכם</strong></div>
												<br></br>
												<div class="services__txt">איסוף מסמכים והכנת בקשה לפניה לבנקים. הגשת בקשות וניהול משא ומתן עד קבלת אישור עקרוני סופי.</div>
											</div>
											<div class="services__body">
												<ul class="services__list">
													<li> הכנת הבקשה </li>
													<li> פניה לבנקים</li>
													<li> קבלת אישורים עקרוניים</li>
													<li> ניהול משא ומתן</li>
													<li> קבלת אישור עקרוני סופי</li>
												</ul>
											</div>
											<div class="services__foot">
												<div class="services__price">מחיר 2950 ₪</div>
												<div class="services__txt">כולל מע"מ</div>
												<div class="services__btn">
													<a href="https://secure.cardcom.solutions/e/Uvro" target="_blank" rel="noopener noreferrer" class="btn btn--sm"><span class="btn__inner">לרכישה</span></a>
												</div>
											</div>
										</div>
										<div class="services__item">
											<div class="services__head">
												<div class="services__icon">
													<img src="/img/price-single-house-desktop.png" alt="" />
												</div>
												<div class="services__title"> <strong>קבלת</strong> משכנתא</div>
												<br></br>
												<div class="services__txt">השלמת מסמכים נדרשים לקבלת משכנתא וליווי תהליך הביצוע מול הבנק עד החתימה.</div>
											</div>
											<div class="services__body">
												<ul class="services__list">
													<li> טיפול בקבלת מסמכים </li>
													<li> ליווי תהליך אישור המשכנתא </li>
													<li> חתימה על המשכנתא </li>
												</ul>
											</div>
											<div class="services__foot">
												<div class="services__price">מחיר 2000 ₪</div>
												<div class="services__txt">כולל מע"מ</div>
												<div class="services__btn">
													<a href="https://secure.cardcom.solutions/e/Uvru" target="_blank" rel="noopener noreferrer" class="btn btn--sm"><span class="btn__inner">לרכישה</span></a>
												</div>
											</div>
										</div>
									</div>
								</div>

							</section>
						</article>
						
						<section class="home__clients-review clients-review-home">
							<div class="clients-review-home__container">
								<h2 class="clients-review-home__title _section-title">לקוחות אופטימאי ממליצים</h2>

								<div class="gallery-slider-wrapper">
									<div class="gallery-slider-button prev" onClick={() => SliderPrev()}></div>
									<div class="gallery-slider" id="gallery-slider">
										{sliderItems.map((slide, index) => (
											<div class="gallery-slider__item" id={"gallery-slider__item-" + (index + 1)}>
												<a onClick={() => openVideo(slide.link)} class="gallery-img">
													<img src={"/img/" + slide.img} alt="" />
													<span class="play-button"></span>
												</a>
											</div>
										))}
									</div>
									<div class="gallery-slider-button next" onClick={() => SliderNext()}></div>
								</div>

								<div class="gallery-slider__pagination slider-pagination" SetItemNum>
									{sliderPoints.map((item, index) => (
										<div onClick={() => SetItemNum(index + 1)} class={currSlide === (index + 1) ? 'slider-bullets slider-bullets--active' : 'slider-bullets'}></div>
									))}
								</div>

							</div>
						</section>
					</main>
					
					<Footer />
				</div>
			</body>
		</>
	);
};
