import { isEmptyArray } from 'formik';
import * as React from 'react';
import { ActiveSelectInput } from '../../components/optimaiComponents/active-select-input';
// import '../css/style.css';

export const ServeyStepOne = ({ serveyObject, setServeyObject, setCurrentStep, currentStep, setOptimaiPopup }) => {
  const [questionTwo, setQuestionTwo] = React.useState(false);
  const [questionThree, setQuestionThree] = React.useState(false);
  const [nextButton, setNextButton] = React.useState(false);
  const [answeredQuestions, setAnsweredQuestions] = React.useState(0);
  const [localServeyObject, setLocalServeyObject] = React.useState(0);
  const [localMortgageGoal, setLocalMortgageGoal] = React.useState(0);
  const warningLifeSecs = 5000;
  const mortgageGoalList = [
    { name: 'רכישת דירה ראשונה', value: '1', precent: '75' },
    { name: 'החלפת דירה יחידה', value: '2', precent: '70' },
    { name: 'רכישת דירה נוספת', value: '3', precent: '50' },
    { name: 'מיחזור משכנתא', value: '4', precent: '75' },
    { name: 'כיסוי הלוואות', value: '5', precent: '50' },
    { name: 'מטרה אחרת', value: '6', precent: '50' }
  ];

  const IsEmpty = (value) => {
    const m = value === 0 || value === '' || value === null || value === undefined;
    return m;
  };

  const NotEmpty = (value) => {
    const m = value !== 0 && value !== '' && value !== null && value !== undefined;
    return m;
  };

  const flipString = (value) => {
    let output = [];
    if (value.length === 1) {
      return value;
    }
    for (let i = value.length; i >= 0; i--) {
      output.push(value[i]);
    }

    return output.join('');
  };

  const mask = (num) => {
    let output = [];

    num = num.toString();

    for (let i = 0; i < num.length; i++) {
      if (i === 0 && num[0] === '0') {
        output.push('');
      } else {
        output.push(num[i]);
      }
    }

    num = output.join('');
    output = [];
    let value = flipString(num);

    for (let i = 0; i < value.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        output.push(',');
      }

      if (i !== value.length) {
        output.push(value[i]);
      }
    }

    value = output.join('');

    return flipString(value);
  };

  const restoreServeyObject = () => {
    const restoredServeyObjectString = localStorage.getItem('serveyObject');
    console.log('debug restoredServeyObjectString isempty', IsEmpty(restoredServeyObjectString), restoredServeyObjectString);

    if (NotEmpty(restoredServeyObjectString)) {
      console.log('debug yes I am that bad I set null for serveyObject restoredServeyObjectString', restoredServeyObjectString);
      const serveyObjectReady = JSON.parse(restoredServeyObjectString);
      // localStorage.removeItem('serveyObject');
      setServeyObject(serveyObjectReady); //
      return serveyObjectReady; // 
    }
    return false;
  }; 

  const setValues = (serveyObjectValues) => {
    let newServeyObject = {
      ...localServeyObject,
      ...{
        propertyValue: serveyObjectValues['propertyValue'],
        mortgageAmount: serveyObjectValues['mortgageAmount'],
      }
    };
    setLocalServeyObject(newServeyObject);
    setLocalMortgageGoal(serveyObjectValues['mortgageGoal']);

    if (NotEmpty(serveyObjectValues['propertyValue']) && NotEmpty(serveyObjectValues['mortgageGoal']) && NotEmpty(serveyObjectValues['mortgageAmount'])) {
      setAnsweredQuestions('openNextButton');
      const propertyValue = document.querySelector('#propertyValue');
      propertyValue.value = mask(serveyObjectValues['propertyValue']);
      const mortgageAmount = document.querySelector('#mortgageAmount');
      mortgageAmount.value = mask(serveyObjectValues['mortgageAmount']);
      const mortgageGoal = document.querySelector("#mortgageGoal .selectbox__text");
      mortgageGoal.innerHTML = serveyObjectValues['mortgageGoal'].name;
    }
  };

  React.useEffect(() => {
    restoreServeyObject(); 

    window.scrollTo({ top: 0 });
  }, []);

  React.useEffect(() => {
    if (serveyObject) {
      setValues(serveyObject);
    } 

    console.log('dubg serveyObject', serveyObject);
  }, [serveyObject]);

  const removeCommons = (value) => {
    let output = [];

    for (let i = 0; i < value.length; i++) {
      if (value[i] === ',' || !(value[i] >= '0' && value[i] <= '9')) {
        output.push('');
      } else {
        output.push(value[i]);
      }
    }

    return output.join('');
  }; 

  const saveServeyResult = () => {
    let newServeyObject = {
      ...serveyObject,
      ...{
        propertyValue: localServeyObject['propertyValue'],
        mortgageAmount: localServeyObject['mortgageAmount'],
        mortgageGoal: localMortgageGoal
      }
    };
    setServeyObject(newServeyObject);
    console.log('debug newServeyObject', newServeyObject);
    localStorage.setItem('serveyObject', JSON.stringify(newServeyObject));
    setCurrentStep(currentStep + 1);
  };

  const readInputValue = (identificator, prevEmptyMassage) => {
    const el = document.getElementById(identificator);
    el.classList.remove("error");
    const pr = document.getElementById(prevEmptyMassage);
    if (pr !== null) {
      pr.classList.add("none");
    }
    const localServeyObjectSave = {};
    const inputValue = removeCommons(el.value);
    console.log('inputValue: ', inputValue);
    localServeyObjectSave[identificator] = inputValue;
    let newLocalServeyObject = {
      ...localServeyObject,
      ...localServeyObjectSave
    };
    setLocalServeyObject(newLocalServeyObject);
    const readyNum = mask(removeCommons(el.value));
    console.log('readyNum: ', readyNum);
    el.value = readyNum; 
  };

  React.useEffect(() => {
    if (answeredQuestions === 'openQuestionTwo') {
      setQuestionTwo(true);
      setQuestionThree(false);
      setNextButton(false);
    } else if (answeredQuestions === 'openQuestionThree') {
      setQuestionTwo(true);
      setQuestionThree(true);
      setNextButton(false);
    } else if (answeredQuestions === 'openNextButton') {
      setQuestionTwo(true);
      setQuestionThree(true);
      setNextButton(true);
    }
  }, [answeredQuestions]);

  const MaxAsk = (property, percent) => {
    return Math.round(property * (percent / 100));
  };

  const IsValid = (amount, property, percent) => {
    return (amount <= (property * (percent / 100)) && NotEmpty(amount) && NotEmpty(property));
  };

  const PropertyValid = (num) => {
    return num <= 35000000 && num >= 400000 && NotEmpty(num);
  };

  const MortgageValid = (num) => {
    return num >= 100000 && NotEmpty(num);
  };

  const showFieldWarning = (currImputMassageId, prevInputId) => {
    const me = document.getElementById(currImputMassageId);
    me.classList.remove("none");
    setTimeout(() => {
      me.classList.add("none");
    }, warningLifeSecs);

    const el = document.getElementById(prevInputId);
    el.classList.add("error");
  };

  const listenInput = (event) => {
    const el = document.getElementById(event.target.id);
  };

  React.useEffect(() => {
    if (NotEmpty(localMortgageGoal) && answeredQuestions === 0) {
      setAnsweredQuestions('openQuestionTwo');
    } else if (IsEmpty(localMortgageGoal) && questionTwo) {
      setAnsweredQuestions(0);
    }
  }, [localMortgageGoal]);

  React.useEffect(() => {
    if (NotEmpty(localServeyObject['propertyValue']) && PropertyValid(localServeyObject['propertyValue']) && questionTwo) {
      setAnsweredQuestions('openQuestionThree');
    } else if ((IsEmpty(localServeyObject['propertyValue']) || !PropertyValid(localServeyObject['propertyValue'])) && (questionThree || nextButton)) {
      setAnsweredQuestions('openQuestionTwo');
    }
  }, [localServeyObject['propertyValue']]);

  React.useEffect(() => {
    let valid = false;

    if (localMortgageGoal !== undefined) {
      valid = IsValid(localServeyObject['mortgageAmount'], localServeyObject['propertyValue'], localMortgageGoal['precent']);
    }
    
    if (NotEmpty(localServeyObject['mortgageAmount']) && questionThree && valid && PropertyValid(localServeyObject['propertyValue']) && MortgageValid(localServeyObject['mortgageAmount'])) {
      setAnsweredQuestions('openNextButton');
    } else if (IsEmpty(localServeyObject['mortgageAmount']) && NotEmpty(localServeyObject['propertyValue']) && nextButton) {
      setAnsweredQuestions('openQuestionThree');
    }
  }, [localServeyObject['mortgageAmount']]);

  React.useEffect(() => {
    let valid = false;

    if (localMortgageGoal !== undefined) {
      valid = IsValid(localServeyObject['mortgageAmount'], localServeyObject['propertyValue'], localMortgageGoal['precent']);
    }

    if ((!valid || !MortgageValid(localServeyObject['mortgageAmount'])) && nextButton && NotEmpty(localServeyObject['propertyValue']) && PropertyValid(localServeyObject['propertyValue'])) {
      setAnsweredQuestions('openQuestionThree');
    } else if (valid && questionTwo && PropertyValid(localServeyObject['propertyValue']) && MortgageValid(localServeyObject['mortgageAmount'])) {
      setAnsweredQuestions('openNextButton');
    }
  }, [localServeyObject, localMortgageGoal]);

  return (
    <>
      <div class="home-page__bg-1">
        <img src="img/home-page-bg-1.png" alt="" />
      </div>
      <div class="container">

        <div class="home-page__logo">
          <img src="img/calculator-logo.png" alt="" onClick={() => setOptimaiPopup(true)} />
        </div>
        <div class="home-page__wrapper">
          <h1 class="home-page__title">דרך קלה <span class="lemash">למשכנתא מעולה </span></h1>
          <div class="home-page__form hp-form">
            <div class="hp-form__row">
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <img src="img/homeicon3.png" alt="" />
                  <span>סכום המשכנתא</span>
                </div>
                <div class="hp-form__field">
                  <div className="form-input-style2-wrapper">
                    {!questionThree ? (
                      <div class="form-input-style2-disabled" onClick={() => showFieldWarning('question-three-prev-empty', 'propertyValue')}></div>
                    ) : ''}
                    <input type="text" inputmode="numeric" class="form-input-style2" onChange={() => readInputValue('mortgageAmount', '')} id="mortgageAmount" placeholder="הקלד" disabled={!questionThree} />
                  </div>
                  <div class="form-message none" Id="question-three-prev-empty">
                    <div class="form-message__text">חסר נתון קודם</div>
                    <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                  </div>
                  {localServeyObject['mortgageAmount'] < 100000 && questionThree && (localServeyObject['mortgageAmount'] <= MaxAsk(localServeyObject['propertyValue'], localMortgageGoal.precent) || localServeyObject['mortgageAmount'] === undefined) ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        תוכל לבקש עד {mask(MaxAsk(localServeyObject['propertyValue'], localMortgageGoal.precent))} ש"ח
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                  {!IsEmpty(localServeyObject['mortgageAmount']) && questionThree && localServeyObject['mortgageAmount'] <= MaxAsk(localServeyObject['propertyValue'], localMortgageGoal.precent) && localServeyObject['mortgageAmount'] >= 100000 ? (
                    <div class="form-message">
                      <div class="form-message__text">יוצאים לדרך! לחץ על התחל.</div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                  {!IsEmpty(localServeyObject['mortgageAmount']) && questionThree && localServeyObject['mortgageAmount'] > MaxAsk(localServeyObject['propertyValue'], localMortgageGoal.precent) ? (
                    <div class="form-message">
                      <div class="form-message__text">הגזמת! הזן סכום עד {mask(MaxAsk(localServeyObject['propertyValue'], localMortgageGoal.precent))} ש"ח</div>
                      <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                </div>
              </div>
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <img src="img/homeicon2.png" alt="" />
                  <span>שווי הנכס</span>
                </div>
                <div class="hp-form__field">
                  <div className="form-input-style2-wrapper">
                    {!questionTwo ? (
                      <div class="form-input-style2-disabled" onClick={() => showFieldWarning('question-two-prev-empty', 'mortgageGoal')}></div>
                    ) : ''}
                    <input type="text" class="form-input-style2" inputmode="numeric" onClick={listenInput} onChange={() => readInputValue('propertyValue', 'question-three-prev-empty')} id="propertyValue" placeholder="הקלד" disabled={!questionTwo} />
                  </div>
                  <div class="form-message none" Id="question-two-prev-empty">
                    <div class="form-message__text">חסר נתון קודם</div>
                    <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                  </div>
                  {questionTwo && ((localServeyObject['propertyValue'] >= 0 && localServeyObject['propertyValue'] < 400000) || localServeyObject['propertyValue'] === undefined) ? (
                    <div class="form-message">
                      <div class="form-message__text">כמה שווה הנכס שעליו תרשם המשכנתא?</div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                  {!IsEmpty(localServeyObject['propertyValue']) && questionTwo && PropertyValid(localServeyObject['propertyValue']) ? (
                    <div class="form-message">
                      <div class="form-message__text">המשך לבחירת סכום המשכנתא.</div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                  {localServeyObject['propertyValue'] > 35000000 && questionTwo ? (
                    <div class="form-message">
                      <div class="form-message__text">שווי נכס מירבי לחישוב הוא 35 מליון.</div>
                      <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                </div>
              </div>
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <img src="img/homeicon1.png" alt="" />
                  <span>מטרת המשכנתא</span>
                </div>
                <div class="hp-form__field">
                  <ActiveSelectInput selectOptions={mortgageGoalList} value={localMortgageGoal} setValue={setLocalMortgageGoal} selectId="mortgageGoal" />
                  {IsEmpty(localMortgageGoal) ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        למה מיועדים כספי ההלוואה?
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : (
                    <div class="form-message">
                      <div class="form-message__text">
                        ניתן לקבל עד {localMortgageGoal.precent}% משווי הנכס.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="hp-form__button">
              <div class="hp-form__cb">
                <img src="img/big-star.png" alt="" class="big-star" />
                <img src="img/big-star.png" alt="" class="small-star" />
                <button type="button" class="button next-button" onClick={() => saveServeyResult()} disabled={!nextButton}>התחל</button>
              </div>
            </div>
          </div>
        </div>
        <div class="home-page__bg-3">
          <img src="img/home-page-bg-3b.png" alt="" />
        </div>
        <div class="home-page__bg-4">
          <img src="img/home-page-bg-4b.png" alt="" /> 
        </div>
      </div>
      <div class="home-page__bg-22 ">
        <img src="img/home-page-bg-2.png" alt="" />
      </div>
    </>
  );
};
