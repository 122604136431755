import axios from 'axios';
import { isEmptyArray } from 'formik';
import * as React from 'react';
import { ActiveSelectInput } from '../../components/optimaiComponents/active-select-input';
import { GetServeyServerObjectReady } from '../../services/dateRangeService';
import { Get, Post, Serialize } from '../../services/http';
// import '../css/style.css';

export const ServeyStepFive = ({ serveyObject, setServeyObject, setCurrentStep, currentStep }) => {
  const [questionTwo, setQuestionTwo] = React.useState(false);
  const [questionThree, setQuestionThree] = React.useState(false);
  const [nextButton, setNextButton] = React.useState(false);
  const [answeredQuestions, setAnsweredQuestions] = React.useState(0);
  const [localServeyObject, setLocalServeyObject] = React.useState(0);
  const [localSeniority, setLocalSeniority] = React.useState(0);
  const seniorityList = [
    { name: '0', value: '1' },
    { name: '1-2', value: '2' },
    { name: '3-5', value: '3' },
    { name: '5+', value: '3' }
  ];

  const openRatingPage = (id) => {
    window.location.href = '/rating/' + id;
  };

  const saveServeyResult = async () => {
    let newServeyObject = {
      ...serveyObject,
      ...{
        repayments: localServeyObject['loanRepayments'],
        monthlyIncome: localServeyObject['monthlyIncome'],
        seniority: localSeniority,
        action: 'calc_mortagage'
      }
    };
    setServeyObject(newServeyObject);
    localStorage.setItem('serveyObject', JSON.stringify(newServeyObject)); // 
    const serverObj = GetServeyServerObjectReady(newServeyObject);
    console.log('result our serverObj', serverObj);
    console.log('result our final servey object', newServeyObject);
    
    const paramsObj = { ...serverObj, action: 'calc_mortagage' };
    const params = Serialize(paramsObj);
     axios.get('https://optimai.co.il/wp-admin/admin-ajax.php?' + params).then(function (response) {
      console.log('result ', response.data);
      openRatingPage(response.data);
    }); /**/

    /* const result = await Get('https://optimai.co.il/wp-admin/admin-ajax.php', { action: 'calc_mortagage' }); */
  };

  const prevStep = () => {
    let newServeyObject = {
      ...serveyObject,
      ...{
        loanRepayments: localServeyObject['loanRepayments'],
        monthlyIncome: localServeyObject['monthlyIncome'],
        seniority: localSeniority
      }
    };
    setServeyObject(newServeyObject);
    localStorage.setItem('serveyObject', JSON.stringify(newServeyObject));
    setCurrentStep(currentStep - 1);
  };

  const IsEmpty = (value) => {
    const m = value === 0 || value === '' || value === null || value === undefined;
    return m;
  };

  const NotEmpty = (value) => {
    const m = value !== 0 && value !== '' && value !== null && value !== undefined;
    return m;
  };

  const flipString = (value) => {
    let output = [];
    if (value.length === 1) {
      return value;
    }
    for (let i = value.length; i >= 0; i--) {
      output.push(value[i]);
    }

    return output.join('');
  };

  const mask = (num) => {
    let output = [];

    num = num.toString();

    for (let i = 0; i < num.length; i++) {
      if (i === 0 && num[0] === '0') {
        output.push('');
      } else {
        output.push(num[i]);
      }
    }

    num = output.join('');
    output = [];
    let value = flipString(num);

    for (let i = 0; i < value.length; i++) {
      if (i !== 0 && i % 3 === 0) {
        output.push(',');
      }

      if (i !== value.length) {
        output.push(value[i]);
      }
    }

    value = output.join('');

    return flipString(value);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0 });

    let newServeyObject = {
      ...localServeyObject,
      ...{
        loanRepayments: serveyObject['loanRepayments'],
        monthlyIncome: serveyObject['monthlyIncome'],
      }
    };
    setLocalSeniority(serveyObject['seniority']);
    console.log('serveyObject localSen', localSeniority);
    setLocalServeyObject(newServeyObject);

    const loanRepayments = document.querySelector('#loanRepayments');
    if (serveyObject['loanRepayments'] !== undefined) {
      loanRepayments.value = mask(serveyObject['loanRepayments']);
    }
    const monthlyIncome = document.querySelector('#monthlyIncome');
    if (serveyObject['monthlyIncome'] !== undefined) {
      monthlyIncome.value = mask(serveyObject['monthlyIncome']);
    }
    
    if (NotEmpty(serveyObject['seniority'])) {
      const seniority = document.querySelector("#seniority .selectbox__text");
      seniority.innerHTML = serveyObject['seniority'].name;
    }

    console.log('serveyObject', serveyObject);
  }, []);

  const removeCommons = (value) => {
    let output = [];

    for (let i = 0; i < value.length; i++) {
      if (value[i] === ',' || !(value[i] >= '0' && value[i] <= '9')) {
        output.push('');
      } else {
        output.push(value[i]);
      }
    }

    return output.join('');
  };

  const valid = (mortgage, income, repayments) => {
    if (NotEmpty(repayments)) {
      return (mortgage / 100) <= (income - repayments);
    }
    return (mortgage / 100) <= income;
  };

  const readInputValue = (identificator, prevEmptyMassage) => {
    const el = document.getElementById(identificator);
    el.classList.remove("error");
    const pr = document.getElementById(prevEmptyMassage);
    if (pr !== null) {
      pr.classList.add("none");
    }
    const localServeyObjectSave = {};
    const inputValue = removeCommons(el.value);
    console.log('inputValue: ', inputValue);
    localServeyObjectSave[identificator] = inputValue;
    let newLocalServeyObject = {
      ...localServeyObject,
      ...localServeyObjectSave
    };
    setLocalServeyObject(newLocalServeyObject);
    const readyNum = mask(removeCommons(el.value));
    console.log('readyNum: ', readyNum);
    el.value = readyNum;
  };

  React.useEffect(() => {
    if (answeredQuestions === 0) {
      setQuestionThree(false);
      setNextButton(false);
    } else if (answeredQuestions === 'openQuestionThree') {
      setQuestionThree(true);
      setNextButton(false);
    } else if (answeredQuestions === 'openNextButton') {
      setQuestionThree(true);
      setNextButton(true);
    }
  }, [answeredQuestions]);

  React.useEffect(() => {
    const val = valid(serveyObject['mortgageAmount'], localServeyObject['monthlyIncome'], localServeyObject['loanRepayments']);

    if (NotEmpty(localServeyObject['monthlyIncome']) && IsEmpty(localSeniority) && answeredQuestions === 0 && val) {
      setAnsweredQuestions('openQuestionThree');
    } else if (IsEmpty(localServeyObject['monthlyIncome']) || !val) {
      setAnsweredQuestions(0);
    }

    if (val && NotEmpty(localSeniority)) {
      setAnsweredQuestions('openNextButton');
    }

    if (NotEmpty(localServeyObject['monthlyIncome']) && NotEmpty(localSeniority) && val) {
      setAnsweredQuestions('openNextButton');
    }
  }, [localSeniority, localServeyObject]);

  return (
    <>
      <div class="home-page__bg-1">
        <img src="img/home-page-bg-1.png" alt="" />
      </div>
      <div class="container">
        <div class="step">
          <div class="step__item">
            <div class="step__inner step__inner--active">
              4
            </div>
          </div>
          <div class="step__item">
            <div class="step__inner step__inner--active">
              3
            </div>
          </div>
          <div class="step__item ">
            <div class="step__inner step__inner--active">
              2
            </div>
          </div>
          <div class="step__item">
            <div class="step__inner step__inner--active">
              1
            </div>
          </div>
        </div>
        <div class="home-page__wrapper">
          <div class="home-page__form hp-form">
            <div class="hp-form__row">
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>ותק בעבודה</span>
                </div>
                <div class="hp-form__field">
                  <ActiveSelectInput selectOptions={seniorityList} value={localSeniority} setValue={setLocalSeniority} disabled={!questionThree} selectId="seniority" EmptyMassage="question-three-prev-empty" prevSelectId="monthlyIncome" />
                  <div class="form-message none" Id="question-three-prev-empty">
                    <div class="form-message__text">חסר נתון קודם</div>
                    <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                  </div>
                  {IsEmpty(localSeniority) && questionThree ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        כמה שנים אתה עוסק בעיסוק הנוכחי?
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                  {NotEmpty(localSeniority) && questionThree ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        לחץ בצע חישוב ותן לי כמה רגעים.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                </div>
              </div>
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>הכנסה חודשית</span>
                </div>
                <div class="hp-form__field">
                  <input type="text" class="form-input-style2" onChange={() => readInputValue('monthlyIncome', 'question-three-prev-empty')} id="monthlyIncome" inputmode="numeric" placeholder="הקלד" />
                  {IsEmpty(localServeyObject['monthlyIncome']) ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        הזן הכנסה חודשית נטו של כל משק הבית.
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                  {NotEmpty(localServeyObject['monthlyIncome']) && valid(serveyObject['mortgageAmount'], localServeyObject['monthlyIncome'], localServeyObject['loanRepayments']) ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        מעולה. עוד שאלה אחרונה וסיימנו.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                  {NotEmpty(localServeyObject['monthlyIncome']) && !valid(serveyObject['mortgageAmount'], localServeyObject['monthlyIncome'], localServeyObject['loanRepayments']) ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        ההכנסה לא מספיקה להחזרי המשכנתא.
                      </div>
                      <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                </div>
              </div>
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>החזר הלוואות</span>
                </div>
                <div class="hp-form__field">
                  <input type="text" class="form-input-style2" onChange={() => readInputValue('loanRepayments', '')} id="loanRepayments" inputmode="numeric" placeholder="הקלד" />
                  <div class="form-message">
                    <div class="form-message__text">אם אין הלוואות השאר ריק.</div>
                    <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hp-form__buttons">
              <button type="button" class="button active" onClick={prevStep}>הקודם</button>
              <button type="button" class="button next-button" onClick={() => saveServeyResult()} disabled={!nextButton}>בצע חישוב</button>
            </div>
          </div>
        </div>
      </div>
      <div class="home-page__bg-2">
        <img src="img/home-page-bg-2.4.png" alt="" class="hidden-iphone" />
        <img src="img/home-page-bg-2.4-mobile.png" alt="" class="visible-iphone" />
      </div>
    </>
  );
};
