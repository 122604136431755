import * as React from 'react';
import Lottie from 'react-lottie';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Get, Post, Serialize } from '../services/http';

import buttonData from '../animations/source/calculator-button.json';
import bannerData from '../animations/source/banner.json';

import { LinkCss } from '../services/cssService';

let interval;

export const Header = () => {
	const [scrollPosition, setScrollPosition] = React.useState(0);

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	React.useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	React.useEffect(() => {
		let header = document.getElementById("main-header");

		if (scrollPosition >= 50) {
			header.classList.add("fixed");
		} else {
			header.classList.remove("fixed");
		}
	}, [scrollPosition]);

	const buttonAnimation = {
		loop: true,
		autoplay: true,
		animationData: buttonData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	};

	const mobileMenu = () => {
		let menu = document.getElementById("menu__body");

		console.log("menu.className", menu.className);

		if (menu.className === "menu__body active") {
			menu.classList.remove("active");
		} else {
			menu.classList.add("active");
		}
	};

	(function (d) { 
		let s = d.createElement("script"); 
		s.setAttribute("data-account", "es8YPEWAQK"); 
		s.setAttribute("src", "https://cdn.userway.org/widget.js"); 
		(d.body || d.head).appendChild(s); 
	}(document));

	return (
		<>
			<noscript>Please ensure Javascript is enabled for purposes of <a href="https://userway.org">website accessibility</a></noscript>

			<header class="main-header" id="main-header">
				<div class="main-header__container">
					<div class="main-header__menu menu">
						<a href="/" class="menu__logo">
							<img src="img/logo.svg" alt="Logo" />
						</a>
						<button type="button" class="menu__icon icon-menu" onClick={() => mobileMenu()}><span></span></button>
						<nav class="menu__body" id="menu__body">
							<ul class="menu__list">
								<li class="menu__item"><a href="/" class="menu__link">דף בית</a></li>
								<li class="menu__item"><a href="/#how-it-works" class="menu__link">איך זה עובד</a></li>
								<li class="menu__item"><a href="/#about-container" class="menu__link">אודות</a></li>
								<li class="menu__item"><a href="/team" class="menu__link">צוות אופטימאי</a></li>
								<li class="menu__item"><a href="/services" class="menu__link">מחירון</a></li>
								<li class="menu__item"><a href="/contact-us" class="menu__link">צור קשר</a></li>
								<li class="menu__item blog"><a href="/blog/" class="menu__link menu__link_blog">Blog</a></li>
							</ul>
							<ul class="menu__socials socials-menu">
								<li class="socials-menu__item">
									<a href="https://www.linkedin.com/company/optimaismart/" class="socials-menu__link">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.65 24.65">
											<path class="socials-menu__image" d="M12.32,0A12.33,12.33,0,1,0,24.65,12.32,12.33,12.33,0,0,0,12.32,0ZM8.26,19.06H5.63c-.14,0-.19,0-.19-.18,0-3.13,0-6.25,0-9.38,0-.12,0-.17.17-.17H8.28c.17,0,.2.06.19.21v4.67c0,1.54,0,3.08,0,4.63C8.48,19,8.43,19.06,8.26,19.06ZM7,8A1.75,1.75,0,1,1,8.71,6.23,1.76,1.76,0,0,1,7,8ZM19.82,18.86c0,.16-.05.2-.21.2H17c-.15,0-.18,0-.18-.19V14a4.45,4.45,0,0,0-.14-1.21,1.36,1.36,0,0,0-1.5-1.06,1.63,1.63,0,0,0-1.71,1.63,6.33,6.33,0,0,0,0,.76c0,1.57,0,3.15,0,4.73,0,.15,0,.2-.2.2H10.56c-.14,0-.18,0-.18-.18V9.52c0-.16,0-.19.19-.19h2.52c.15,0,.19.05.19.19,0,.38,0,.75,0,1.12.2-.22.37-.46.57-.65a3.18,3.18,0,0,1,2.28-.9,5.24,5.24,0,0,1,1.43.17,2.8,2.8,0,0,1,2,2.09,8.84,8.84,0,0,1,.27,2.4C19.82,15.45,19.81,17.15,19.82,18.86Z" />
										</svg>
									</a>
								</li>
								<li class="socials-menu__item">
									<a href="https://www.youtube.com/channel/UCUDv1V_0Td_fDHV9SVYpWuA" class="socials-menu__link">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.65 24.65">
											<polygon class="socials-menu__image" points="10.68 14.79 14.95 12.32 10.68 9.86 10.68 14.79" />
											<path class="socials-menu__image" d="M12.32,0A12.33,12.33,0,1,0,24.65,12.32,12.33,12.33,0,0,0,12.32,0ZM20.2,16.28a2.06,2.06,0,0,1-1.46,1.45,48.18,48.18,0,0,1-6.42.35,48.25,48.25,0,0,1-6.42-.35,2.06,2.06,0,0,1-1.45-1.45,21.63,21.63,0,0,1-.34-4,21.54,21.54,0,0,1,.34-4A2.06,2.06,0,0,1,5.9,6.92a48.25,48.25,0,0,1,6.42-.35,48.18,48.18,0,0,1,6.42.35A2.06,2.06,0,0,1,20.2,8.37a21.54,21.54,0,0,1,.34,4A21.63,21.63,0,0,1,20.2,16.28Z" />
										</svg>
									</a>
								</li>
								<li class="socials-menu__item">
									<a href="https://www.facebook.com/profile.php?id=100084017982900" class="socials-menu__link">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.65 24.5">
											<path class="socials-menu__image" d="M24.65,12.32A12.33,12.33,0,1,0,10.4,24.5V15.89H7.27V12.32H10.4V9.61c0-3.09,1.84-4.8,4.65-4.8a19,19,0,0,1,2.76.24v3H16.26a1.78,1.78,0,0,0-2,1.92v2.31h3.42l-.55,3.57H14.25V24.5A12.33,12.33,0,0,0,24.65,12.32Z" />
										</svg>
									</a>
								</li>
							</ul>
						</nav>
					</div>
					<a href="/calculator" class="main-header__calculator">
						<Lottie
							options={buttonAnimation}
							speed={2}
						/>
					</a>
				</div>
			</header>

		</>
	);
};
