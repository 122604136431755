import * as React from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { ServeyStepOne } from './serveySteps/ServeyStepOne';
import { ServeyStepTwo } from './serveySteps/ServeyStepTwo';
import { ServeyStepThree } from './serveySteps/ServeyStepThree';
import { ServeyStepFour } from './serveySteps/ServeyStepFour';
import { ServeyStepFive } from './serveySteps/ServeyStepFive';
import { Get, Post, Serialize } from '../services/http';
import { LinkCss } from '../services/cssService';

let interval;

export const Results = () => {
	let popupTimeOut = 180000;
	const [videoLink, setVideoLink] = React.useState();
	const [openVideoPopup, setOpenVideoPopup] = React.useState(false);
	const { id } = useParams();
	const [cffResult, setCffResult] = React.useState('');
	const [upperCalc, setUpperCalc] = React.useState(null);
	const [routh, setRouth] = React.useState(null);
	const [phonePopup, setPhonePopup] = React.useState(null);
	const [optimaiPopup, setOptimaiPopup] = React.useState(null);
	const [serveyCount, setServeyCount] = React.useState(0);
	const [madadStability, setMadadStability] = React.useState(null);
	const [formObject, setFormObject] = React.useState({ firstName: "", lastName: "", phone: "", email: "", mortgage: "", terms: "" });
	let sliderItemNum = 0;
	const [currSlide, setCurrSlide] = React.useState(3);
	let currSLideVar = 3;
	let intervals = [];
	let timeOuts = [];
	const [slideSide, setSlideSide] = React.useState('');
	const [totalRoutes, setTotalRoutes] = React.useState(null);
	const sliderItems = [
		{ link: "1f_mXeR4aGY", img: "int1fixed.jpg" },
		{ link: "mkf7Ep5XmFc", img: "int2.jpg" },
		{ link: "0LAQhZf8eM0", img: "int1a.jpg" },
		{ link: "53WNwn5D11A", img: "int1b.jpg" },
		{ link: "mkf7Ep5XmFc", img: "int2.jpg" }
	];
	const sliderPoints = [
		{ index: 1 },
		{ index: 2 },
		{ index: 3 },
		{ index: 4 },
		{ index: 5 }
	];
	let amount_1 = 0;
	let amount_2 = 0;
	let amount_3 = 0;

	React.useEffect(() => {
		LinkCss("/css/style.css");
	}, []);

	const openVideo = (link) => {
		console.log("clicked");
		setVideoLink(link);
		setOpenVideoPopup(true);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	const calcPMT = (apr, term, loan) => {
		const terms = term * 12;
		const aprs = apr / 1200;
		const amount = (aprs * -loan * (1 + aprs) ** terms) / (1 - (1 + aprs) ** terms);
		return Math.round(amount);
	};

	const closeVideo = () => {
		console.log("clicked");
		setOpenVideoPopup(false);
	};

	const calcMashkanta = (event) => {
		console.log('our input value', event.target.value);
		const rawYears = event.target.value;
		const years = rawYears / 2;
		const min = cffResult?.data?.mortgageAmount * 0.34;
		const k = (cffResult?.data?.mortgageAmount - min) / 99;
		const globalAmount = cffResult?.data?.mortgageAmount;
		amount_1 = Math.round(globalAmount * 0.34);
		amount_2 = Math.round(globalAmount * 0.33);
		amount_3 = Math.round(globalAmount * 0.33);
		let tkufa_1 = event.target.value * 6;
		let tkufa_2 = event.target.value * 6;
		let tkufa_3 = (event.target.value * 6) % 60 === 0 ? event.target.value * 6 : (event.target.value * 6) + (60 - ((event.target.value * 6) % 60));
		/// amount_1, amount_2, amount_3
		const rebit_1 = (parseFloat(cffResult.route[0].periodicIntrest) + 1.5 + (((tkufa_1 / 12) - 5) / 25)).toPrecision(3);
		const ihzer_1 = calcPMT(rebit_1, (tkufa_1 / 12), amount_1);
		const total_ihzer_1 = ihzer_1 * tkufa_1;
		const rebit_2 = (amount_2 / globalAmount) <= 0.33 ? (parseFloat(cffResult.route[1].periodicIntrest) + 0.75).toPrecision(3) : (parseFloat(cffResult.route[1].periodicIntrest) + 0.75 + ((amount_2 / globalAmount) - 0.33) * 1.5).toPrecision(3);
		const ihzer_2 = calcPMT(rebit_2, (tkufa_2 / 12), amount_2);
		const total_ihzer_2 = ihzer_2 * tkufa_2;
		const rebit_3 = (parseFloat(cffResult.route[2].periodicIntrest) + 1.5).toPrecision(3);
		const ihzer_3 = calcPMT(rebit_3, (tkufa_3 / 12), amount_3);
		const total_ihzer_3 = ihzer_3 * tkufa_3;
		setRouth({
			amount_1: amount_1.toLocaleString(),
			amount_2: amount_2.toLocaleString(),
			amount_3: amount_3.toLocaleString(),
			rebit_1: rebit_1,
			tkufa_1: tkufa_1,
			ihzer_1: ihzer_1.toLocaleString(),
			total_ihzer_1: total_ihzer_1.toLocaleString(),
			rebit_2: rebit_2,
			tkufa_2: tkufa_2,
			ihzer_2: ihzer_2.toLocaleString(),
			total_ihzer_2: total_ihzer_2.toLocaleString(),
			rebit_3: rebit_3,
			tkufa_3: tkufa_3,
			ihzer_3: ihzer_3.toLocaleString(),
			total_ihzer_3: total_ihzer_3.toLocaleString(),
		});
		setTotalRoutes({
			mortgageAmount: (parseInt(cffResult.data.mortgageAmount)).toLocaleString(),
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
		});
		setUpperCalc({
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			avarageYears: ((tkufa_1 / 12) * (amount_1 / globalAmount) + (tkufa_2 / 12) * (amount_2 / globalAmount) + (tkufa_3 / 12) * (amount_3 / globalAmount)).toPrecision(3),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
			refundPerShekel: ((total_ihzer_1 + total_ihzer_2 + total_ihzer_3) / (parseInt(cffResult.data.mortgageAmount))).toPrecision(3),
			intrestLevel: ((parseFloat(rebit_1) + parseFloat(rebit_2) + parseFloat(rebit_3)) / 3).toPrecision(3), 
		});
		/*
		const tkufa1SliderEl = document.getElementById('tkufa1');
		tkufa1SliderEl.value = tkufa_1 / 6;
		const tkufa1SliderElMobile = document.getElementById('tkufa1_mobile');
		tkufa1SliderElMobile.value = tkufa_1 / 6;

		const tkufa2SliderEl = document.getElementById('tkufa2');
		tkufa2SliderEl.value = tkufa_2 / 6;
		const tkufa2SliderElMobile = document.getElementById('tkufa2_mobile');
		tkufa2SliderElMobile.value = tkufa_2 / 6;

		const tkufa3SliderEl = document.getElementById('tkufa3');
		tkufa3SliderEl.value = tkufa_3 / 60;
		const tkufa3SliderElMobile = document.getElementById('tkufa3_mobile');
		tkufa3SliderElMobile.value = tkufa_3 / 60;
		*/
	};

	const calcMashkantaByRoutesSlider1 = (event) => {
		const min = Math.round(cffResult?.data?.mortgageAmount * 0.34);
		const k = (cffResult?.data?.mortgageAmount - min) / 99;
		const globalAmount = cffResult?.data?.mortgageAmount;
		amount_1 = Math.round(k * (event.target.value - 1)) + min;
		console.log('our input value', event.target.value);
		const other_amounts = globalAmount - amount_1;
		amount_2 = Math.round(other_amounts / 2);
		amount_3 = other_amounts - amount_2;
		/// amount_1, amount_2, amount_3
		const rebit_1 = (parseFloat(cffResult.route[0].periodicIntrest) + 1.5 + ((parseInt(cffResult.route[0].installments) - 5) / 25)).toPrecision(3);
		const tkufa_1 = parseInt(routh.tkufa_1);
		const ihzer_1 = calcPMT(rebit_1, (tkufa_1 / 12), amount_1);
		const total_ihzer_1 = ihzer_1 * tkufa_1;
		const rebit_2 = (amount_2 / globalAmount) <= 0.33 ? (parseFloat(cffResult.route[1].periodicIntrest) + 0.75).toPrecision(3) : (parseFloat(cffResult.route[1].periodicIntrest) + 0.75 + ((amount_2 / globalAmount) - 0.33) * 1.5).toPrecision(3);
		const tkufa_2 = parseInt(routh.tkufa_2); // document.querySelector("#tkufa2").value;
		const ihzer_2 = calcPMT(rebit_2, (tkufa_2 / 12), amount_2);
		const total_ihzer_2 = ihzer_2 * tkufa_2;

		const rebit_3 = (parseFloat(cffResult.route[2].periodicIntrest) + 1.5).toPrecision(3);
		const tkufa_3 = parseInt(routh.tkufa_3);
		const ihzer_3 = calcPMT(rebit_3, (tkufa_3 / 12), amount_3);
		const total_ihzer_3 = ihzer_3 * tkufa_3;
		setRouth({
			amount_1: amount_1.toLocaleString(),
			amount_2: amount_2.toLocaleString(),
			amount_3: amount_3.toLocaleString(),
			rebit_1: rebit_1,
			tkufa_1: tkufa_1,
			ihzer_1: ihzer_1.toLocaleString(),
			total_ihzer_1: total_ihzer_1.toLocaleString(),
			rebit_2: rebit_2,
			tkufa_2: tkufa_2,
			ihzer_2: ihzer_2.toLocaleString(),
			total_ihzer_2: total_ihzer_2.toLocaleString(),
			rebit_3: rebit_3,
			tkufa_3: tkufa_3,
			ihzer_3: ihzer_3.toLocaleString(),
			total_ihzer_3: total_ihzer_3.toLocaleString(),
		});
		setTotalRoutes({
			mortgageAmount: (parseInt(cffResult.data.mortgageAmount)).toLocaleString(),
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
		});
		setUpperCalc({
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			avarageYears: ((tkufa_1 / 12) * (amount_1 / globalAmount) + (tkufa_2 / 12) * (amount_2 / globalAmount) + (tkufa_3 / 12) * (amount_3 / globalAmount)).toPrecision(3),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
			refundPerShekel: ((total_ihzer_1 + total_ihzer_2 + total_ihzer_3) / (parseInt(cffResult.data.mortgageAmount))).toPrecision(3),
			intrestLevel: ((parseFloat(rebit_1) + parseFloat(rebit_2) + parseFloat(rebit_3)) / 3).toPrecision(3),  
		});
		const newMadadStability = (100 - (((amount_2 / globalAmount) * 30) + ((amount_3 / globalAmount) * 20))).toPrecision(3);
		setMadadStability(newMadadStability);
	};

	const calcMashkantaByRoutesSlider2 = (event) => {
		let amount_1_b = 0;
		let amount_2_b = 0;
		let amount_3_b = 0;
		amount_1_b = parseInt((document.querySelector("#amount1").getAttribute("data-value")).replace(/,/g, ''));
		amount_1_b = Math.round(amount_1_b);
		const globalAmount = cffResult?.data?.mortgageAmount;
		const other_amounts = (globalAmount - amount_1_b);
		const k = other_amounts / 100;
		amount_2_b = (Math.round(k * event.target.value));
		amount_3_b = (other_amounts - amount_2_b);
		console.log('our input value', event.target.value);
		const rebit_2 = (amount_2_b / globalAmount) <= 0.33 ? (parseFloat(cffResult.route[1].periodicIntrest) + 0.75).toPrecision(3) : (parseFloat(cffResult.route[1].periodicIntrest) + 0.75 + ((amount_2_b / globalAmount) - 0.33) * 1.5).toPrecision(3);
		const tkufa_2 = parseInt(routh.tkufa_2);
		const ihzer_2 = calcPMT(rebit_2, (tkufa_2 / 12), amount_2_b);

		const rebit_1 = (parseFloat(cffResult.route[0].periodicIntrest) + 1.5 + ((parseInt(cffResult.route[0].installments) - 5) / 25)).toPrecision(3);
		let tkufa_1 = parseInt(routh.tkufa_1); // document.querySelector("#tkufa1").value;
		const ihzer_1 = calcPMT(rebit_1, (tkufa_1 / 12), amount_1_b);
		const total_ihzer_1 = ihzer_1 * tkufa_1;
		const total_ihzer_2 = ihzer_2 * tkufa_2;

		const rebit_3 = (parseFloat(cffResult.route[2].periodicIntrest) + 1.5).toPrecision(3);
		const tkufa_3 = parseInt(routh.tkufa_3); // document.querySelector("#tkufa3").value;
		const ihzer_3 = calcPMT(rebit_3, (tkufa_3 / 12), amount_3_b);
		const total_ihzer_3 = ihzer_3 * tkufa_3;
		setRouth({
			amount_1: amount_1_b.toLocaleString(),
			amount_2: amount_2_b.toLocaleString(),
			amount_3: amount_3_b.toLocaleString(),
			rebit_1: rebit_1,
			tkufa_1: tkufa_1,
			ihzer_1: ihzer_1.toLocaleString(),
			total_ihzer_1: total_ihzer_1.toLocaleString(),
			rebit_2: rebit_2,
			tkufa_2: tkufa_2,
			ihzer_2: ihzer_2.toLocaleString(),
			total_ihzer_2: total_ihzer_2.toLocaleString(),
			rebit_3: rebit_3,
			tkufa_3: tkufa_3,
			ihzer_3: ihzer_3.toLocaleString(),
			total_ihzer_3: total_ihzer_3.toLocaleString(),
		});
		setTotalRoutes({
			mortgageAmount: (parseInt(cffResult.data.mortgageAmount)).toLocaleString(),
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
		});
		setUpperCalc({
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			avarageYears: ((tkufa_1 / 12) * (amount_1_b / globalAmount) + (tkufa_2 / 12) * (amount_2_b / globalAmount) + (tkufa_3 / 12) * (amount_3_b / globalAmount)).toPrecision(3),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
			refundPerShekel: ((total_ihzer_1 + total_ihzer_2 + total_ihzer_3) / (parseInt(cffResult.data.mortgageAmount))).toPrecision(3),
			intrestLevel: ((parseFloat(rebit_1) + parseFloat(rebit_2) + parseFloat(rebit_3)) / 3).toPrecision(3), 
		});
		const newMadadStability = (100 - (((amount_2_b / globalAmount) * 30) + ((amount_3_b / globalAmount) * 20))).toPrecision(3);
		setMadadStability(newMadadStability);
	};

	const calcMashkantaByHodeshSlider1 = (event) => {
		// new value event.target.value
		console.log('our new slider by hodesh', event.target.value);
		const min = Math.round(cffResult?.data?.mortgageAmount * 0.34);
		const k = 6;
		const globalAmount = cffResult?.data?.mortgageAmount;
		/// amount_1, amount_2, amount_3
		amount_1 = parseInt((document.querySelector("#amount1").getAttribute("data-value")).replace(/,/g, ''));
		amount_1 = Math.round(amount_1);
		amount_2 = parseInt((document.querySelector("#amount2").getAttribute("data-value")).replace(/,/g, ''));
		amount_2 = Math.round(amount_2);
		amount_3 = globalAmount - (amount_1 + amount_2);
		let tkufa_1 = event.target.value * 6; // document.querySelector("#tkufa1").value;
		const rebit_1 = (parseFloat(cffResult.route[0].periodicIntrest) + 1.5 + ((tkufa_1 / 12) - 5) / 25).toPrecision(3);
		const ihzer_1 = calcPMT(rebit_1, (tkufa_1 / 12), amount_1);
		const total_ihzer_1 = ihzer_1 * tkufa_1;
		const rebit_2 = (amount_2 / globalAmount) <= 0.33 ? (parseFloat(cffResult.route[1].periodicIntrest) + 0.75).toPrecision(3) : (parseFloat(cffResult.route[1].periodicIntrest) + 0.75 + ((amount_2 / globalAmount) - 0.33) * 1.5).toPrecision(3);
		const tkufa_2 = parseInt(routh.tkufa_2);
		const ihzer_2 = calcPMT(rebit_2, (tkufa_2 / 12), amount_2);
		const total_ihzer_2 = ihzer_2 * tkufa_2;

		const rebit_3 = (parseFloat(cffResult.route[2].periodicIntrest) + 1.5).toPrecision(3);
		const tkufa_3 = parseInt(routh.tkufa_3); // document.querySelector("#tkufa3").value;
		const ihzer_3 = calcPMT(rebit_3, (tkufa_3 / 12), amount_3);
		const total_ihzer_3 = ihzer_3 * tkufa_3;
		setRouth({
			amount_1: amount_1.toLocaleString(),
			amount_2: amount_2.toLocaleString(),
			amount_3: amount_3.toLocaleString(),
			rebit_1: rebit_1,
			tkufa_1: tkufa_1,
			ihzer_1: ihzer_1.toLocaleString(),
			total_ihzer_1: total_ihzer_1.toLocaleString(),
			rebit_2: rebit_2,
			tkufa_2: tkufa_2,
			ihzer_2: ihzer_2.toLocaleString(),
			total_ihzer_2: total_ihzer_2.toLocaleString(),
			rebit_3: rebit_3,
			tkufa_3: tkufa_3,
			ihzer_3: ihzer_3.toLocaleString(),
			total_ihzer_3: total_ihzer_3.toLocaleString(),
		});
		setTotalRoutes({
			mortgageAmount: (parseInt(cffResult.data.mortgageAmount)).toLocaleString(),
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
		});
		setUpperCalc({
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			avarageYears: ((tkufa_1 / 12) * (amount_1 / globalAmount) + (tkufa_2 / 12) * (amount_2 / globalAmount) + (tkufa_3 / 12) * (amount_3 / globalAmount)).toPrecision(3),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
			refundPerShekel: ((total_ihzer_1 + total_ihzer_2 + total_ihzer_3) / (parseInt(cffResult.data.mortgageAmount))).toPrecision(3),
			intrestLevel: ((parseFloat(rebit_1) + parseFloat(rebit_2) + parseFloat(rebit_3)) / 3).toPrecision(3), 
		});
	};

	const calcMashkantaByHodeshSlider2 = (event) => {
		console.log('our new slider by hodesh', event.target.value);
		const min = cffResult?.data?.mortgageAmount * 0.34;
		const k = 6;
		const globalAmount = cffResult?.data?.mortgageAmount;
		/// amount_1, amount_2, amount_3
		amount_1 = parseInt((document.querySelector("#amount1").getAttribute("data-value")).replace(/,/g, ''));
		amount_1 = Math.round(amount_1);
		amount_2 = parseInt((document.querySelector("#amount2").getAttribute("data-value")).replace(/,/g, ''));
		amount_2 = Math.round(amount_2);
		amount_3 = globalAmount - (amount_1 + amount_2);
		const tkufa_1 = parseInt(routh.tkufa_1); // document.querySelector("#tkufa1").value;
		const rebit_1 = (parseFloat(cffResult.route[0].periodicIntrest) + 1.5 + ((tkufa_1 / 12) - 5) / 25).toPrecision(3);
		const ihzer_1 = calcPMT(rebit_1, (tkufa_1 / 12), amount_1);
		const total_ihzer_1 = ihzer_1 * tkufa_1;
		const rebit_2 = (amount_2 / globalAmount) <= 0.33 ? (parseFloat(cffResult.route[1].periodicIntrest) + 0.75).toPrecision(3) : (parseFloat(cffResult.route[1].periodicIntrest) + 0.75 + ((amount_2 / globalAmount) - 0.33) * 1.5).toPrecision(3);
		const tkufa_2 = k * event.target.value; // document.querySelector("#tkufa2").value;
		const ihzer_2 = calcPMT(rebit_2, (tkufa_2 / 12), amount_2);
		const total_ihzer_2 = ihzer_2 * tkufa_2;

		const rebit_3 = (parseFloat(cffResult.route[2].periodicIntrest) + 1.5).toPrecision(3);
		const tkufa_3 = parseInt(routh.tkufa_3); // document.querySelector("#tkufa3").value;
		const ihzer_3 = calcPMT(rebit_3, (tkufa_3 / 12), amount_3);
		const total_ihzer_3 = ihzer_3 * tkufa_3;
		setRouth({
			amount_1: amount_1.toLocaleString(),
			amount_2: amount_2.toLocaleString(),
			amount_3: amount_3.toLocaleString(),
			rebit_1: rebit_1,
			tkufa_1: tkufa_1,
			ihzer_1: ihzer_1.toLocaleString(),
			total_ihzer_1: total_ihzer_1.toLocaleString(),
			rebit_2: rebit_2,
			tkufa_2: tkufa_2,
			ihzer_2: ihzer_2.toLocaleString(),
			total_ihzer_2: total_ihzer_2.toLocaleString(),
			rebit_3: rebit_3,
			tkufa_3: tkufa_3,
			ihzer_3: ihzer_3.toLocaleString(),
			total_ihzer_3: total_ihzer_3.toLocaleString(),
		});
		setTotalRoutes({
			mortgageAmount: (parseInt(cffResult.data.mortgageAmount)).toLocaleString(),
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
		});
		setUpperCalc({
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			avarageYears: ((tkufa_1 / 12) * (amount_1 / globalAmount) + (tkufa_2 / 12) * (amount_2 / globalAmount) + (tkufa_3 / 12) * (amount_3 / globalAmount)).toPrecision(3),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
			refundPerShekel: ((total_ihzer_1 + total_ihzer_2 + total_ihzer_3) / (parseInt(cffResult.data.mortgageAmount))).toPrecision(3),
			intrestLevel: ((parseFloat(rebit_1) + parseFloat(rebit_2) + parseFloat(rebit_3)) / 3).toPrecision(3), 
		});
	};

	const calcMashkantaByHodeshSlider3 = (event) => {
		console.log('our new slider by hodesh', event.target.value);
		const min = cffResult?.data?.mortgageAmount * 0.34;
		const k = 6;
		const globalAmount = cffResult?.data?.mortgageAmount;
		/// amount_1, amount_2, amount_3
		amount_1 = parseInt((document.querySelector("#amount1").getAttribute("data-value")).replace(/,/g, ''));
		amount_1 = Math.round(amount_1);
		amount_2 = parseInt((document.querySelector("#amount2").getAttribute("data-value")).replace(/,/g, ''));
		amount_2 = Math.round(amount_2);
		amount_3 = globalAmount - (amount_1 + amount_2);
		const tkufa_1 = parseInt(routh.tkufa_1); // document.querySelector("#tkufa1").value;
		const rebit_1 = (parseFloat(cffResult.route[0].periodicIntrest) + 1.5 + ((tkufa_1 / 12) - 5) / 25).toPrecision(3);
		const ihzer_1 = calcPMT(rebit_1, (tkufa_1 / 12), amount_1);
		const total_ihzer_1 = ihzer_1 * tkufa_1;
		const rebit_2 = (amount_2 / globalAmount) <= 0.33 ? (parseFloat(cffResult.route[1].periodicIntrest) + 0.75).toPrecision(3) : (parseFloat(cffResult.route[1].periodicIntrest) + 0.75 + ((amount_2 / globalAmount) - 0.33) * 1.5).toPrecision(3);
		const tkufa_2 = parseInt(routh.tkufa_2); // document.querySelector("#tkufa2").value;
		const ihzer_2 = calcPMT(rebit_2, (tkufa_2 / 12), amount_2);
		const total_ihzer_2 = ihzer_2 * tkufa_2;

		const rebit_3 = (parseFloat(cffResult.route[2].periodicIntrest) + 1.5).toPrecision(3);
		const tkufa_3 = event.target.value * 60; // document.querySelector("#tkufa3").value;
		const ihzer_3 = calcPMT(rebit_3, (tkufa_3 / 12), amount_3);
		const total_ihzer_3 = ihzer_3 * tkufa_3;
		setRouth({
			amount_1: amount_1.toLocaleString(),
			amount_2: amount_2.toLocaleString(),
			amount_3: amount_3.toLocaleString(),
			rebit_1: rebit_1,
			tkufa_1: tkufa_1,
			ihzer_1: ihzer_1.toLocaleString(),
			total_ihzer_1: total_ihzer_1.toLocaleString(),
			rebit_2: rebit_2,
			tkufa_2: tkufa_2,
			ihzer_2: ihzer_2.toLocaleString(),
			total_ihzer_2: total_ihzer_2.toLocaleString(),
			rebit_3: rebit_3,
			tkufa_3: tkufa_3,
			ihzer_3: ihzer_3.toLocaleString(),
			total_ihzer_3: total_ihzer_3.toLocaleString(),
		});
		setTotalRoutes({
			mortgageAmount: (parseInt(cffResult.data.mortgageAmount)).toLocaleString(),
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
		});
		setUpperCalc({
			ihzer: (ihzer_1 + ihzer_2 + ihzer_3).toLocaleString(),
			avarageYears: ((tkufa_1 / 12) * (amount_1 / globalAmount) + (tkufa_2 / 12) * (amount_2 / globalAmount) + (tkufa_3 / 12) * (amount_3 / globalAmount)).toPrecision(3),
			totalIhzer: (total_ihzer_1 + total_ihzer_2 + total_ihzer_3).toLocaleString(),
			refundPerShekel: ((total_ihzer_1 + total_ihzer_2 + total_ihzer_3) / (parseInt(cffResult.data.mortgageAmount))).toPrecision(3),
		});
	};

	/* const calcMashkantaBottom = (event) => {
		console.log('our input value', event.target.value);
		const rawYears = event.target.value;
		setBottomCalc({
			term: rawYears,
			mortgageAmount: cffResult?.data?.mortgageAmount,
			MonthlyRepayments: calcPMT(4.20, rawYears, 247500),
			total: cffResult?.data?.mortgageAmount * bottomCalc?.RefundPerShekel,
			RefundPerShekel: 1.22,
			payPerMonth: Math.round(bottomCalc.total / (rawYears * 12))
		});
	}; */

	const getServeyResult = async () => {
		let newServeyObject = {
			...{
				action: 'get_cff_result_by_id'
			}
		};
		let form_data = new FormData();
		form_data.append('action', 'get_cff_result_by_id');
		const paramsObj = { action: 'get_cff_result_by_id', proposal_id: id.toString() };
		const params = Serialize(paramsObj);
		axios.get('https://optimai.co.il/wp-admin/admin-ajax.php?' + params).then(function (response) {
			console.log('result ', response.data); // data from php
			console.log('maslol 1 tkufa', response.data.route[0].installments);
			setServeyCount(parseInt(response.data?.data?.id) + 250000);
			setCffResult(response.data);
			const term = 30;
			setUpperCalc({ // data alion
				term: 50,
				mortgageAmount: response.data.result.mortgageAmount,
				MonthlyRepayments: calcPMT(4.20, term, 247500),
				RefundPerShekel: response.data.result.payPerShekel,
				total: response?.data?.data.mortgageAmount * 1.22,
				payPerMonth: Math.round((response?.data?.data.mortgageAmount * 1.22) / (term * 12))
			});
			const ihzer_1 = (calcPMT((parseFloat(response.data.route[0].periodicIntrest) + 1.5 + ((parseInt(response.data.route[0].installments) - 5) / 25)).toPrecision(3), response.data.route[0].installments, Math.round(response.data.data.mortgageAmount * (0.34)))).toLocaleString();
			const ihzer_2 = (calcPMT((parseFloat(response.data.route[1].periodicIntrest) + 0.75).toPrecision(3), response.data.route[1].installments, Math.round(response.data.data.mortgageAmount * (0.33)))).toLocaleString();
			const ihzer_3 = (calcPMT((parseFloat(response.data.route[2].periodicIntrest) + 1.5).toPrecision(3), response.data.route[2].installments, Math.round(response.data.data.mortgageAmount * (0.33)))).toLocaleString();

			setRouth({ /// data shel maslolim
				amount_1: (Math.round(response.data.data.mortgageAmount * (0.34))).toLocaleString(),
				amount_2: (Math.round(response.data.data.mortgageAmount * (0.33))).toLocaleString(),
				amount_3: (Math.round(response.data.data.mortgageAmount * (0.33))).toLocaleString(),
				rebit_1: (parseFloat(response.data.route[0].periodicIntrest) + 1.5 + ((parseInt(response.data.route[0].installments) - 5) / 25)).toPrecision(3),
				tkufa_1: response.data.route[0].installments * 12,

				total_ihzer_1: (calcPMT((parseFloat(response.data.route[0].periodicIntrest) + 1.5 + ((parseInt(response.data.route[0].installments) - 5) / 25)).toPrecision(3), response.data.route[0].installments, Math.round(response.data.data.mortgageAmount * (0.34))) * response.data.route[0].installments * 12).toLocaleString(),
				ihzer_1,
				rebit_2: (parseFloat(response.data.route[1].periodicIntrest) + 0.75).toPrecision(3),
				tkufa_2: response.data.route[1].installments * 12,
				total_ihzer_2: (calcPMT((parseFloat(response.data.route[1].periodicIntrest) + 0.75).toPrecision(3), response.data.route[1].installments, Math.round(response.data.data.mortgageAmount * (0.33))) * response.data.route[1].installments * 12).toLocaleString(),
				ihzer_2,
				rebit_3: (parseFloat(response.data.route[2].periodicIntrest) + 1.5).toPrecision(3),
				ihzer_3,
				tkufa_3: response.data.route[2].installments * 12,
				total_ihzer_3: (calcPMT((parseFloat(response.data.route[2].periodicIntrest) + 1.5).toPrecision(3), response.data.route[2].installments, Math.round(response.data.data.mortgageAmount * (0.33))) * response.data.route[2].installments * 12).toLocaleString(),
			});
			setTotalRoutes({
				mortgageAmount: (parseInt(response.data.data.mortgageAmount)).toLocaleString(),
				ihzer: (parseInt(ihzer_1.replace(/,/g, '')) + parseInt(ihzer_2.replace(/,/g, '')) + parseInt(ihzer_3.replace(/,/g, ''))).toLocaleString(),
				totalIhzer: ((calcPMT((parseFloat(response.data.route[0].periodicIntrest) + 1.5 + ((parseInt(response.data.route[0].installments) - 5) / 25)).toPrecision(3), response.data.route[0].installments, Math.round(response.data.data.mortgageAmount * (0.34))) * response.data.route[0].installments * 12) + (calcPMT((parseFloat(response.data.route[1].periodicIntrest) + 0.75).toPrecision(3), response.data.route[1].installments, Math.round(response.data.data.mortgageAmount * (0.33))) * response.data.route[1].installments * 12) + (calcPMT((parseFloat(response.data.route[2].periodicIntrest) + 1.5).toPrecision(3), response.data.route[2].installments, Math.round(response.data.data.mortgageAmount * (0.33))) * response.data.route[2].installments * 12)).toLocaleString(),
			});
			setUpperCalc({
				ihzer: (parseInt(ihzer_1.replace(/,/g, '')) + parseInt(ihzer_2.replace(/,/g, '')) + parseInt(ihzer_3.replace(/,/g, ''))).toLocaleString(),
				avarageYears: ((response.data.route[0].installments) * (0.34) + (response.data.route[1].installments) * (0.33) + (response.data.route[2].installments) * (0.33)).toPrecision(3),
				totalIhzer: ((calcPMT((parseFloat(response.data.route[0].periodicIntrest) + 1.5 + ((parseInt(response.data.route[0].installments) - 5) / 25)).toPrecision(3), response.data.route[0].installments, Math.round(response.data.data.mortgageAmount * (0.34))) * response.data.route[0].installments * 12) + (calcPMT((parseFloat(response.data.route[1].periodicIntrest) + 0.75).toPrecision(3), response.data.route[1].installments, Math.round(response.data.data.mortgageAmount * (0.33))) * response.data.route[1].installments * 12) + (calcPMT((parseFloat(response.data.route[2].periodicIntrest) + 1.5).toPrecision(3), response.data.route[2].installments, Math.round(response.data.data.mortgageAmount * (0.33))) * response.data.route[2].installments * 12)).toLocaleString(),
				refundPerShekel: (((calcPMT((parseFloat(response.data.route[0].periodicIntrest) + 1.5 + ((parseInt(response.data.route[0].installments) - 5) / 25)).toPrecision(3), response.data.route[0].installments, Math.round(response.data.data.mortgageAmount * (0.34))) * response.data.route[0].installments * 12) + (calcPMT((parseFloat(response.data.route[1].periodicIntrest) + 0.75).toPrecision(3), response.data.route[1].installments, Math.round(response.data.data.mortgageAmount * (0.33))) * response.data.route[1].installments * 12) + (calcPMT((parseFloat(response.data.route[2].periodicIntrest) + 1.5).toPrecision(3), response.data.route[2].installments, Math.round(response.data.data.mortgageAmount * (0.33))) * response.data.route[2].installments * 12)) / (parseInt(response.data.data.mortgageAmount))).toPrecision(3),
				intrestLevel: ((parseFloat((parseFloat(response.data.route[0].periodicIntrest) + 1.5 + ((parseInt(response.data.route[0].installments) - 5) / 25))) + (parseFloat(response.data.route[1].periodicIntrest) + 0.75) + (parseFloat(response.data.route[2].periodicIntrest) + 1.5)) / 3).toPrecision(3), 
			});
			setMadadStability(83.5);
			/* setRouthOne({
				amount: Math.round(cffResult?.data?.mortgageAmount * (1 / 3)),
			}); */
			// console.log("routhOne?.mortgageAmount", routhOne?.mortgageAmount);
			/* setBottomCalc({
				term: 20.5,
				mortgageAmount: response.data.result.mortgageAmount,
				MonthlyRepayments: calcPMT(4.20, upperCalc?.term, 247500),
				RefundPerShekel: response.data.result.payPerShekel,
				total: response?.data?.data.mortgageAmount * 1.22,
				payPerMonth: Math.round((response?.data?.data.mortgageAmount * 1.22) / (20.5 * 12))
			}); */
		});

		/* const result = await Get('https://optimai.co.il/wp-admin/admin-ajax.php', { action: 'calc_mortagage' }); */
	};

	const flipString = (value) => {
		let output = [];

		if (value.length === 1) {
			return value;
		}

		for (let i = value.length; i >= 0; i--) {
			output.push(value[i]);
		}

		return output.join('');
	};

	const mask = (num) => {
		let output = [];

		num = num.toString();

		for (let i = 0; i < num.length; i++) {
			if (i === 0 && num[0] === '0') {
				output.push('');
			} else {
				output.push(num[i]);
			}
		}

		num = output.join('');
		output = [];
		let value = flipString(num);

		for (let i = 0; i < value.length; i++) {
			if (i !== 0 && i % 3 === 0) {
				output.push(',');
			}

			if (i !== value.length) {
				output.push(value[i]);
			}
		}

		value = output.join('');

		return flipString(value);
	};

	const initAccordion = () => {
		const boxes = document.querySelectorAll('.spoller__head');

		console.log('boxes', boxes);

		for (const box of boxes) {
			box.addEventListener('click', function handleClick() {
				const parents = document.querySelectorAll('.spoller__item');
				const parent = box.closest(".spoller__item");

				if (parent.className === 'spoller__item active') {
					for (let i = 0; i < parents.length; i++) {
						parents[i].classList.remove('active');
					}
				} else {
					for (let i = 0; i < parents.length; i++) {
						parents[i].classList.remove('active');
					}
					parent.classList.add('active');
				}
			});
		}
	};

	const SliderNum = (num) => {
		if (num === (sliderItems.length + 2)) {
			num = 2;
		}

		if (num === (sliderItems.length + 1)) {
			num = 1;
		}

		if (num === 0) {
			num = sliderItems.length;
		}

		if (num === -1) {
			num = sliderItems.length - 1;
		}

		return num;
	};

	const SliderNextAuto = () => {
		let itemNum = currSLideVar;

		itemNum--;

		setSlideSide('left');
		console.log('SliderNum(itemNum)', SliderNum(itemNum), itemNum);
		setCurrSlide(SliderNum(itemNum));
		currSLideVar = SliderNum(itemNum);
	};

	const startAnimation = () => {
		clearInterval(interval);
		interval = setInterval(SliderNextAuto, 3000);
	};

	const SliderNext = () => {
		clearInterval(interval);
		let itemNum = currSlide;

		itemNum--;

		setSlideSide('left');
		setCurrSlide(SliderNum(itemNum));
		currSLideVar = SliderNum(itemNum);
		interval = setTimeout(startAnimation, 10000);
	};

	const SliderPrev = () => {
		clearInterval(interval);
		let itemNum = currSlide;

		itemNum++;

		setSlideSide('right');
		setCurrSlide(SliderNum(itemNum));
		currSLideVar = SliderNum(itemNum);
		interval = setTimeout(startAnimation, 10000);
	};

	const SetItemNum = (num) => {
		if (num > currSlide) {
			setSlideSide('right');
		} else if (num < currSlide) {
			setSlideSide('left');
		}

		setCurrSlide(num);
	};

	React.useEffect(() => {
		let slideItems = document.querySelectorAll('.gallery-slider__item');

		for (let i = 0; i < slideItems.length; i++) {
			slideItems[i].classList.remove('active');
			slideItems[i].classList.remove('left');
			slideItems[i].classList.remove('right');
			slideItems[i].classList.remove('nowSlide');
			slideItems[i].classList.remove('prevSlide');
			slideItems[i].classList.remove('nextSlide');
			slideItems[i].classList.remove('twoPrevSlide');
			slideItems[i].classList.remove('twoNextSlide');
		}

		let twoNextSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide + 2));
		let nextSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide + 1));
		let nowSlide = document.getElementById('gallery-slider__item-' + currSlide);
		let prevSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide - 1));
		let twoPrevSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide - 2));

		/* console.log('slideDebug twoNextSlide', SliderNum(currSlide + 2));
		console.log('slideDebug nextSlide', SliderNum(currSlide + 1));
		console.log('slideDebug nowSlide', currSlide);
		console.log('slideDebug prevSlide', SliderNum(currSlide - 1));
		console.log('slideDebug twoPrevSlide', SliderNum(currSlide - 2)); */

		nowSlide.classList.add('nowSlide');
		prevSlide.classList.add('prevSlide');
		nextSlide.classList.add('nextSlide');
		twoPrevSlide.classList.add('twoPrevSlide');
		twoNextSlide.classList.add('twoNextSlide');

		if (slideSide === 'left') {
			nowSlide.classList.add('left');
			prevSlide.classList.add('left');
			nextSlide.classList.add('left');
			twoPrevSlide.classList.add('left');
			twoNextSlide.classList.add('left');
		} else {
			nowSlide.classList.add('right');
			prevSlide.classList.add('right');
			nextSlide.classList.add('right');
			twoPrevSlide.classList.add('right');
			twoNextSlide.classList.add('right');
		}

		setTimeout(() => {
			nowSlide.classList.add('active');
			prevSlide.classList.add('active');
			nextSlide.classList.add('active');
			twoPrevSlide.classList.add('active');
			twoNextSlide.classList.add('active');
		}, 100);
	}, [currSlide]);

	React.useEffect(() => {
		getServeyResult();
		initAccordion();
		clearInterval(interval);
	}, []);

	React.useEffect(() => {
		startAnimation();
	}, []);
	
	function validateEmail($email) {
		let emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
		if (emailReg.test($email) && $email !== "") {
			return true;
		}
		return false;
	}

	const validetePhone = (value) => {
		if (value === undefined) {
			return false;
		} else if (value.length !== 10) {
			return false;
		} else {
			return true;
		}
	};

	const valideteEmpty = (value) => {
		if (value === undefined) {
			return false;
		} else if (value.length < 2) {
			return false;
		} else {
			return true;
		}
	};

	const readInputValue = (identificator) => {
		const formObjectSave = {};
		const element = document.getElementById(identificator);
		const elementWrap = element.closest(".form-input-wrapper");
		const inputValue = element.value;
		formObjectSave[identificator] = inputValue;
		let newFormObject = {
			...formObject,
			...formObjectSave 
		};
		setFormObject(newFormObject);

		if (!valideteEmpty(inputValue)) {
			elementWrap.classList.add("notValid");
		} else {
			elementWrap.classList.remove("notValid");
		}
   
		if (identificator === 'email') {
			if (!validateEmail(inputValue) && valideteEmpty(inputValue)) {
				elementWrap.classList.add("notValid");
				elementWrap.classList.add("currValid");
			} else if (validateEmail(inputValue)) {
				elementWrap.classList.remove("notValid");
				elementWrap.classList.remove("currValid");
			} 
		}

		if (identificator === 'phone') {
			if (!validetePhone(inputValue) && valideteEmpty(inputValue)) {
				elementWrap.classList.add("notValid");
				elementWrap.classList.add("currValid");
			} else if (validetePhone(inputValue)) {
				elementWrap.classList.remove("notValid");
				elementWrap.classList.remove("currValid");
			}
		}
	};

	const radioChange = (event) => {
		const element = document.querySelectorAll('.form-radio-wrapper');
		element[0].classList.remove("notValid");

		let value;

		if (event.target.defaultValue === "need") {
			value = 1;
		} else {
			value = 2;
		}

		let newFormObject = {
			...formObject,
			...{
				mortgage: value,
			}
		};
		setFormObject(newFormObject);
	};

	const checkboxChange = (event) => {
		const element = document.querySelectorAll('.form-checkbox-wrapper');
		element[0].classList.remove("notValid");

		let newFormObject = {
			...formObject,
			...{
				terms: event.target.checked
			}
		};
		setFormObject(newFormObject);
	};

	const sendSuccessful = () => {
		const popup = document.querySelectorAll('.form-send-successful');
		popup[0].classList.add("active");

		setTimeout(() => {
			popup[0].classList.remove("active");
		}, 4000);
	};

	const sendForm = () => {
		const inputs = document.querySelectorAll('.form-input-wrapper input');

		for (const input of inputs) {
			const elementWrap = input.closest(".form-input-wrapper");

			if (!valideteEmpty(input.value)) {
				elementWrap.classList.add("notValid");
			}
		}

		if (formObject['mortgage'] === "") {
			const element = document.querySelectorAll('.form-radio-wrapper');
			element[0].classList.add("notValid");
		}

		if (formObject['terms'] === false || formObject['terms'] === "") {
			const element = document.querySelectorAll('.form-checkbox-wrapper');
			element[0].classList.add("notValid");
		}

		if (valideteEmpty(formObject['firstName']) && valideteEmpty(formObject['lastName']) && valideteEmpty(formObject['phone']) && valideteEmpty(formObject['email']) && validateEmail(formObject['email']) && formObject["terms"] === true && formObject['mortgage'] !== "") {
			console.log('send work');
			const sendObject = {
				...cffResult.data,
				...formObject,
				action: 'save_lead'
			};
		
			const params = Serialize(sendObject);
			console.log('sendObject', sendObject);

			axios.get('https://optimai.co.il/wp-admin/admin-ajax.php?' + params).then(function (response) {
				console.log('sendResult', response); 
				for (const input of inputs) {
					input.value = '';
				}
				sendSuccessful();
			}); /* powerlink.co.il */
		}
	};

	React.useEffect(() => {
		console.log("formObject", formObject["terms"]);
	}, [formObject]); 

	React.useEffect(() => {
		setTimeout(() => {
			setPhonePopup(true);
		}, popupTimeOut);
	}, []); 

	const closePhonePopup = () => {
		setPhonePopup(false);
		setTimeout(() => {
			setPhonePopup(true);
		}, popupTimeOut);
	};

	(function (d) { 
		let s = d.createElement("script"); 
		s.setAttribute("data-account", "es8YPEWAQK"); 
		s.setAttribute("src", "https://cdn.userway.org/widget.js"); 
		(d.body || d.head).appendChild(s); 
	}(document));

	return (
		<>
			<Helmet>
				<title>מחשבון משכנתא חכם - אופטימאי</title>
			</Helmet>

			<noscript>Please ensure Javascript is enabled for purposes of <a href="https://userway.org">website accessibility</a></noscript>
			<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9988069504628480" crossorigin="anonymous"></script>
			<body class="loaded results">

				<div id="call-us-popup-modal-container" className={optimaiPopup ? 'optimai' : 'optimai none'}>
					<div id="call-us-modal-wrapper" class="call-us-modal-wrapper">
						<div class="contact-us-popup-wrapper">
							<div class="close-contact-us-popup" onClick={() => setOptimaiPopup(false)}><div class="close-popup-orange-wrapper"><div class="close-popup-white-wrapper"><img src="/img/custom-close-popup-icon.png" alt="" /></div></div></div>
							<div class="contact-us-popup-image-wrapper inline-block">
								<img src="/img/poup-phone-image.png" alt="" />
							</div>
							<div class="contact-us-popup-info-wrapper inline-block">
								<div class="contact-us-popup-ornage-bubble-container">
									<img src="/img/orange-bubble.png" alt="" />
									<div class="contact-us-popup-main-title">אני אופטימאי</div>
									<div class="contact-us-popup-secondary-title">מחשבון משכנתא חכם</div>
								</div>
								<div class="contact-us-popup-text">
									בעוד כמה רגעים אגלה לך<br />כל מה שאתה צריך לדעת<br />על המשכנתא שלך.
								</div>
								<div class="contact-us-popup-blue-button">משהו לא ברור<br />התקשרו <a href="tel:0508121242">050-8121242</a></div>
							</div>
							<div class="popup-button" onClick={() => setOptimaiPopup(false)}>הבנתי!</div>
						</div>
					</div>
				</div>

				<div id="call-us-popup-modal-container" className={phonePopup ? '' : 'none'}>
					<div id="call-us-modal-wrapper" class="call-us-modal-wrapper">
						<div class="contact-us-popup-wrapper">
							<div class="close-contact-us-popup" onClick={() => closePhonePopup()}><div class="close-popup-orange-wrapper"><div class="close-popup-white-wrapper"><img src="/img/custom-close-popup-icon.png" alt="" /></div></div></div>
							<div class="contact-us-popup-image-wrapper inline-block">
								<img src="/img/poup-phone-image.png" alt="" />
							</div>
							<div class="contact-us-popup-info-wrapper inline-block">
								<div class="contact-us-popup-ornage-bubble-container">
									<img src="/img/orange-bubble.png" alt="" />
									<div class="contact-us-popup-main-title">אני אופטימאי</div>
									<div class="contact-us-popup-secondary-title">מחשבון משכנתא חכם</div>
								</div>
								<div class="contact-us-popup-text">
									רוצה לחסוך במשכנתא?
									<br />
									לשירותים של אופטימאי
									<br />
								</div>
								<div class="contact-us-popup-blue-button"> <div>לחצו על הקישור</div> <a href="/services" target="_blank" class="menu__link">השירותים שלנו</a></div>
							</div>
						</div>
					</div>
				</div>

				{/* BEGIN main wrapper */}
				<div class="main-wrapper">

					{/* BEGIN HEADER */}
					<div class="header-wrapper">
						<header class="header">
							<div class="container header__container">
								<div class="header__logo">
									<a class=" logo"><img src="/img/logo2.png" alt="" onClick={() => setOptimaiPopup(true)} /></a>
									<a href="tel:0508121242" class="header-phone">
										050-812-1242
										<img src="/img/phone-icon.png" alt="" />
									</a>
								</div>
								<div class="header__icons h-icons">
									<div class="h-icons__item">
										<div class="h-icons__img">
											<img src="/img/homeicon1.png" alt="" />
										</div>
										<div class="h-icons__title">חישוב מספר</div>
										<div class="h-icons__subtitle">{mask(serveyCount)}</div>
									</div>
									<div class="h-icons__item">
										<div class="h-icons__img">
											<img src="/img/homeicon2.png" alt="" />
										</div>
										<div class="h-icons__title">משכנתא בסך</div>
										<div class="h-icons__subtitle">{cffResult ? mask(cffResult?.data?.mortgageAmount) : ''}</div>
									</div>
									<div class="h-icons__item">
										<div class="h-icons__img">
											<img src="/img/homeicon3.png" alt="" />
										</div>
										<div class="h-icons__title">לנכס בשווי</div>
										<div class="h-icons__subtitle">{cffResult ? mask(cffResult?.data?.propertyValue) : ''}</div>
									</div>
								</div>
								<div class="header__edit">
									<a href="/" class="edit-button">
										<img class="inline-block" src="/img/edit.png" alt="" />
										<span class="inline-block">לייעוץ<br />חכם</span>
									</a>
								</div>
							</div>
						</header>
					</div>
					{/* HEADER EOF   */}

					{/* BEGIN MAIN */}
					<main class="main">
						<div class="set-elem">
							{openVideoPopup === true ? (
								<div id="popup-modal-container">
									<div id="modal-wrapper" class="modal-wrapper" onClick={() => closeVideo()}>
										<div class="close-popup">X</div>
										<iframe width="80%" height="630" src={'https://www.youtube.com/embed/' + videoLink + '?rel=0'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									</div>
								</div>
							) : ''}
							
							<div class="set-elem__right">
								<div class="sel-slider">
									<div class="slidecontainer">

									</div>
									<div class="sel-slider__value">{upperCalc?.ihzer}</div>
									<div class="sel-slider__bar bar">
										<input type="range" min="2" max="60" id="calc-mashkanta" class="big-slider" onChange={calcMashkanta} />
										<div class="bar__row">
											<div class="bar__el-prev">
												החזר מזערי <br /> תקופה מירבית
											</div>
											<div class="bar__icon">
												<img src="/img/finger_icon.png" alt="" />
											</div>
											<div class="bar__el-next">
												החזר מירבי <br /> תקופה מזערית
											</div>

										</div>
									</div>

									<h2 class="sel-slider__title">בחירת החזר חודשי</h2>
									<div class="sel-slider__text">הזז את הכפתור כדי לבחור את ההחזר החודשי הרצוי ולשנות את תקופת המשכנתא.</div>
								</div>
							</div>
							<div class="set-elem__left">
								<div class="numbers">
									<div class="numbers__row">
										<div class="numbers__content">
											<span>{upperCalc?.avarageYears}</span>
											<span>תקופת המשכנתא בשנים</span>
										</div>
										<div class="numbers__icon">
											<img src="/img/num-icon-1.png" alt="" />
										</div>
									</div>
									<div class="numbers__row">
										<div class="numbers__content">
											<span>{upperCalc?.totalIhzer}</span>
											<span>סך כל התשלומים, קרן וריבית.</span>
										</div>
										<div class="numbers__icon">
											<img src="/img/homeicon3.png" alt="" />
										</div>
									</div>
									<div class="numbers__row">
										<div class="numbers__content">
											<span>{upperCalc?.refundPerShekel}</span>
											<span>החזר לכל שקל הלוואה</span>
										</div>
										<div class="numbers__icon">
											<img src="/img/num-icon-3.png" alt="" />
										</div>
									</div>
									<div class="numbers__row">
										<div class="numbers__content">
											<span>{upperCalc?.intrestLevel}% </span>
											<span>ריבית מחושבת</span>
										</div>
										<div class="numbers__icon">
											<img src="/img/num-icon-2.png" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
						{/*
						<section class="section tracks-section">
							<div class="container">
								<div class="section__head">
									<h2 class="section__title">תמהיל<strong> מסלולים</strong></h2>
									<h3 class="section__subtitle">תחזית הריביות שבאפשרותך לקבל לכל מסלול</h3>
								</div>

								<div class="tracks hidden-iphone">
									<div class="tracks__row">
										<div class="tracks__th tracks__1">
											<img src="/img/tableicons-15.png" alt="" />
											<span>סה"כ החזר</span>
										</div>
										<div class="tracks__th tracks__2">
											<img src="/img/tableicons-16.png" alt="" />
											<span>החזר חודשי</span>
										</div>
										<div class="tracks__th tracks__3">
											<img src="/img/tableicons-19.png" alt="" />
											<span>ריבית</span>
										</div>
										<div class="tracks__th tracks__4">
											<img src="/img/tableicons-20.png" alt="" />
											<span>תקופה</span>
										</div>
										<div class="tracks__th tracks__5">
											<img src="/img/tableicons-15.png" alt="" />
											<span>סכום</span>
										</div>
										<div class="tracks__th tracks__6">
											<img src="/img/tableicons-18.png" alt="" />
											<span>שם המסלול</span>
										</div>
										<div class="tracks__th tracks__7">
											<img src="/img/tableicons-18.png" alt="" />
											<span>מספר</span>
										</div>
									</div>
									<div class="tracks__row">
										<div class="tracks__total tracks__1">{routh?.total_ihzer_1}
										</div>
										<div class="tracks__monthly tracks__2">{routh?.ihzer_1}
										</div>
										<div class="tracks__interest tracks__3">{routh?.rebit_1}</div>
										<div class="tracks__period tracks__4">
											{routh?.tkufa_1}
											<input id="tkufa1" data-value={routh?.tkufa_1} type="range" min="2" max="60" class="swiper-bar yellow-slider" onChange={calcMashkantaByHodeshSlider1} />
										</div>
										<div class="tracks__sum tracks__5">
											{routh?.amount_1}
											<input id="amount1" data-value={routh?.amount_1} type="range" min="1" class="swiper-bar yellow-slider" onChange={calcMashkantaByRoutesSlider1} />
										</div>
										<div class="tracks__name tracks__6">
											במסלול זה הריבית לא משתנה.
											<strong>קבועה לא צמודה</strong>
										</div>
										<div class="tracks__number tracks__7">
											1
											<span class="definitions">הגדרות</span>
										</div>
									</div>
									<div class="tracks__row">
										<div class="tracks__total tracks__1">{routh?.total_ihzer_2}</div>
										<div class="tracks__monthly tracks__2">{routh?.ihzer_2}</div>
										<div class="tracks__interest tracks__3">{routh?.rebit_2}</div>
										<div class="tracks__period tracks__4">
											{routh?.tkufa_2}
											<input id="tkufa2" data-value={routh?.tkufa_2} type="range" min="2" max="60" class="swiper-bar yellow-slider" onChange={calcMashkantaByHodeshSlider2} />
										</div>
										<div class="tracks__sum tracks__5">
											{routh?.amount_2}
											<input id="amount2" data-value={routh?.amount_2} type="range" min="0" class="swiper-bar yellow-slider" onChange={calcMashkantaByRoutesSlider2} />
										</div>

										<div class="tracks__name tracks__6">
											במסלול זה הריבית צמודה לריבית בנק ישראל
											<strong>פריים</strong>
										</div>
										<div class="tracks__number tracks__7">
											2
											<span class="definitions">הגדרות</span>
										</div>
									</div>
									<div class="tracks__row">
										<div class="tracks__total tracks__1">{routh?.total_ihzer_3}</div>
										<div class="tracks__monthly tracks__2">{routh?.ihzer_3}</div>
										<div class="tracks__interest tracks__3">{routh?.rebit_3}</div>
										<div class="tracks__period tracks__4">
											{routh?.tkufa_3}
											<input id="tkufa3" type="range" min="1" max="6" class="swiper-bar yellow-slider" onChange={calcMashkantaByHodeshSlider3} />
										</div>
										<div class="tracks__sum tracks__5">
											{routh?.amount_3}
											<input type="range" min="1" class="swiper-bar yellow-slider" id="amount3" disabled />
										</div>
										<div class="tracks__name tracks__6">
											במסלול זה הריבית מתעדכנת כל 5 שנים
											<strong>משתנה כל 5 שנים</strong>
										</div>
										<div class="tracks__number tracks__7">
											3
											<span class="definitions">הגדרות</span>
										</div>
									</div>
									<div class="tracks__foot">
										<div class="tracks__total tracks__1">{totalRoutes?.totalIhzer}</div>
										<div class="tracks__monthly tracks__2">{totalRoutes?.ihzer}</div>
										<div class="tracks__interest tracks__3">&nbsp;</div>
										<div class="tracks__period tracks__4">&nbsp;</div>
										<div class="tracks__sum tracks__5">{totalRoutes?.mortgageAmount}</div>
										<div class="tracks__name tracks__6">
											<strong>סך הכל</strong>
										</div>
										<div class="tracks__number tracks__7">&nbsp;</div>
									</div>
								</div>
							*/}
								{/* mobile area */}
								{/*
								<div class="tracks visible-iphone">
									<div class="tracks__item">
										<div class="tracks__row">
											<div class="tracks__name">
												<div class="tracks__name-text">

													<strong>קבועה לא צמודה</strong>
													במסלול זה הריבית לא משתנה.
												</div>
												<img src="/img/tableicons-18.png" alt="" />
											</div>
											<div class="tracks__th tracks__2">
												<img src="/img/tableicons-16.png" alt="" />
												<span>החזר חודשי</span>
											</div>
											<div class="tracks__th tracks__3">
												<img src="/img/tableicons-19.png" alt="" />
												<span>ריבית</span>
											</div>
											<div class="tracks__th tracks__4">
												<img src="/img/tableicons-20.png" alt="" />
												<span>תקופה</span>
											</div>
											<div class="tracks__th tracks__5">
												<img src="/img/tableicons-15.png" alt="" />
												<span>סכום</span>
											</div>
										</div>
										<div class="tracks__row">

											<div class="tracks__monthly tracks__2">{routh?.ihzer_1}</div>
											<div class="tracks__interest tracks__3">{routh?.rebit_1}</div>
											<div class="tracks__period tracks__4">{routh?.tkufa_1}</div>
											<div class="tracks__sum tracks__5">{routh?.amount_1}</div>
											<div class="tracks__number">מידע והגדרות - מסלול 1</div>
											<div class="tracks__row">
												<div class="tracks__total tracks__1">
													<span class="tracks__label">סה"כ החזר</span>
													{routh?.total_ihzer_1}
												</div>

												<div class="tracks__swiper">
													<span class="tracks__label">שינוי תקופה</span>
													<input id="tkufa1_mobile" data-value={routh?.tkufa_1} type="range" min="2" max="60" class="swiper-bar yellow-slider" onChange={calcMashkantaByHodeshSlider1} />
												</div>
												<div class="tracks__swiper">
													<span class="tracks__label">שינוי סכום</span>
													<input id="amount1_mobile" data-value={routh?.amount_1} type="range" min="1" class="swiper-bar yellow-slider" onChange={calcMashkantaByRoutesSlider1} />
												</div>
											</div>
										</div>
									</div>
									<div class="tracks__item">
										<div class="tracks__row">
											<div class="tracks__name">
												<div class="tracks__name-text">

													<strong>פריים</strong>
													במסלול זה הריבית צמודה לריבית בנק ישראל
												</div>
												<img src="/img/tableicons-18.png" alt="" />
											</div>
											<div class="tracks__th tracks__2">
												<img src="/img/tableicons-16.png" alt="" />
												<span>החזר חודשי</span>
											</div>
											<div class="tracks__th tracks__3">
												<img src="/img/tableicons-19.png" alt="" />
												<span>ריבית</span>
											</div>
											<div class="tracks__th tracks__4">
												<img src="/img/tableicons-20.png" alt="" />
												<span>תקופה</span>
											</div>
											<div class="tracks__th tracks__5">
												<img src="/img/tableicons-15.png" alt="" />
												<span>סכום</span>
											</div>
										</div>
										<div class="tracks__row">

											<div class="tracks__monthly tracks__2">{routh?.ihzer_2}</div>
											<div class="tracks__interest tracks__3">{routh?.rebit_2}</div>
											<div class="tracks__period tracks__4">{routh?.tkufa_2}</div>
											<div class="tracks__sum tracks__5">{routh?.amount_2}</div>
											<div class="tracks__number">מידע והגדרות - מסלול 2</div>
											<div class="tracks__row">
												<div class="tracks__total tracks__1">
													<span class="tracks__label">סה"כ החזר</span>
													{routh?.total_ihzer_2}
												</div>

												<div class="tracks__swiper">
													<span class="tracks__label">שינוי תקופה</span>
													<input id="tkufa2_mobile" data-value={routh?.tkufa_2} type="range" min="2" max="60" class="swiper-bar yellow-slider" onChange={calcMashkantaByHodeshSlider2} />
												</div>
												<div class="tracks__swiper">
													<span class="tracks__label">שינוי סכום</span>
													<input id="amount2_mobile" data-value={routh?.amount_2} type="range" min="1" class="swiper-bar yellow-slider" onChange={calcMashkantaByRoutesSlider2} />
												</div>
											</div>
										</div>
									</div>
									<div class="tracks__item">
										<div class="tracks__row">
											<div class="tracks__name">
												<div class="tracks__name-text">

													<strong>משתנה כל 5 שנים</strong>
													במסלול זה הריבית מתעדכנת כל 5 שנים
												</div>
												<img src="/img/tableicons-18.png" alt="" />
											</div>
											<div class="tracks__th tracks__2">
												<img src="/img/tableicons-16.png" alt="" />
												<span>החזר חודשי</span>
											</div>
											<div class="tracks__th tracks__3">
												<img src="/img/tableicons-19.png" alt="" />
												<span>ריבית</span>
											</div>
											<div class="tracks__th tracks__4">
												<img src="/img/tableicons-20.png" alt="" />
												<span>תקופה</span>
											</div>
											<div class="tracks__th tracks__5">
												<img src="/img/tableicons-15.png" alt="" />
												<span>סכום</span>
											</div>
										</div>
										<div class="tracks__row">

											<div class="tracks__monthly tracks__2">{routh?.ihzer_3}</div>
											<div class="tracks__interest tracks__3">{routh?.rebit_3}</div>
											<div class="tracks__period tracks__4">{routh?.tkufa_3}</div>
											<div class="tracks__sum tracks__5">{routh?.amount_3}</div>
											<div class="tracks__number">מידע והגדרות - מסלול 3</div>
											<div class="tracks__row">
												<div class="tracks__total tracks__1">
													<span class="tracks__label">סה"כ החזר</span>
													{routh?.total_ihzer_3}
												</div>

												<div class="tracks__swiper">
													<span class="tracks__label">שינוי תקופה</span>
													<input id="tkufa3_mobile" data-value={routh?.tkufa_3} type="range" min="1" max="6" class="swiper-bar yellow-slider" onChange={calcMashkantaByHodeshSlider3} />
												</div>
												<div class="tracks__swiper">
													<span class="tracks__label">שינוי סכום</span>
													<input id="amount3_mobile" data-value={routh?.amount_3} type="range" min="1" class="swiper-bar yellow-slider" disabled />
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</section>
						*/}
						{/* mobile area end */}
						{/*
						<section class="section stability-section">
							<div class="container">
								<div class="section__head">
									<h2 class="section__title">יציבות <strong>התמהיל</strong> </h2>
									<h3 class="section__subtitle">מדד לרגישות ההחזר החודשי להשפעות ושינויים</h3>
								</div>

								<div class="tamhilratebg">
									<span>{madadStability}</span>
								</div>
							</div>
						</section>
						*/}
						{/* <section class="section posts-section">
							<div class="container">
								<div class="section__head">
									<h2 class="section__title">אופטימאים על אופטימאי</h2>
									<h3 class="section__subtitle">דברים שכדאי לדעת לפני שלוקחים משכנתא</h3>
								</div>

								<div class="posts">
									<div class="posts__item">
										<div class="post">
											<div onClick={() => openVideo('nrF5KVlzf9w')} class="post__img" style={{ backgroundImage: "url('/img/ofernew.gif')" }}>
												<div class="single-img">
													<img src="/img/red-tall-house.png" alt="" />
												</div>
											</div>
											<div class="post__content">
												<div class="post__title">
													 רו"ח עופר אחדות - הבעיות בשוק המשכנתאות <span>יועץ פיננסי</span>
												</div>
												<div class="post__text">
													מומחה מימון ואשראי מנוסה.<br />
													כיהן במשך 13 שנים בתפקיד סמנכ"ל כספים ומוצר בחברת אשראי בורסאית וניהל אשראי בהיקפי ענק.<br />
													היכרות מעמיקה עם פתרונות אשראי בנקאיים וחוץ בנקאיים.
												</div>
												<div class="post__spoller">
													<div class="spoller spoller--sm">
														<div class="spoller__item">
															<div class="spoller__head ">
																<div class="spoller__title">נסיון</div>
															</div>
															<div class="spoller__body ">
																<p>
																	- למעלה מ 1,000 תיקי אשראי מסחריים.<br />
																	- למעלה מ 300 תיקי אשראי פרטיים.
																</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">השכלה</div>
															</div>
															<div class="spoller__body">
																<p>
																	- תואר ראשון (בהצטיינות) בכלכלה וחשבונואות.<br />
																	- רואה חשבון מוסמך.
																</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">מומחיות</div>
															</div>
															<div class="spoller__body">
																<p>יעוצים פיננסיים במגזר הפרטי והעסקי.</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">מתאים ל</div>
															</div>
															<div class="spoller__body">
																<p>
																	- תכנון עסקאות.<br />
																	- תכנון מבנה אשראי.<br />
																	- אשראי לנדלן עסקי ומסחרי.
																</p>
															</div>
														</div>

													</div>
												</div>
												<div class="post__email">
													<span>ofer@optimai.co.il</span>
													<img src="/img/email-icon.png" alt="" />
												</div>
											</div>

										</div>
									</div>
									<div class="posts__item">
										<div class="post">
											<div onClick={() => openVideo('mE_1uvDK-jc')} class="post__img" style={{ backgroundImage: "url('/img/elirannew.gif')" }}>
												<div class="single-img">
													<img src="/img/white-house-orange-top.png" alt="" />
												</div>
											</div>
											<div class="post__content">
												<div class="post__title">
													אלירן גנסיה - הפתרון של אופטימאי <span>מנהל פיתוח עיסקי</span>
												</div>
												<div class="post__text">

													עוסק בפיתוח כלים מתקדמים מבוססי בינה מלאכותית בתחום המשכנתאות והאשראי.<br />
													בעל היכרות מעמיקה עם תנאי רגולציה של בנק ישראל.

												</div>

												<div class="post__spoller">
													<div class="spoller spoller--sm">
														<div class="spoller__item">
															<div class="spoller__head ">
																<div class="spoller__title">נסיון</div>
															</div>
															<div class="spoller__body ">
																<p>
																	בשנים האחרונות ייעצתי, ליוויתי ונתתי מענה ליותר מ 600 משקי בית.
																</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">השכלה</div>
															</div>
															<div class="spoller__body">
																<p>
																	- מהנדס תוכנה מטעם הטכניון.<br />
																	- מומחה מערכות ניתוח ריביות ותחזיות פיננסיות.
																</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">מומחיות</div>
															</div>
															<div class="spoller__body">
																<p>ניתוח רמות סיכון תחזיות ריביות ותנאי אשראי.</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">מתאים ל</div>
															</div>
															<div class="spoller__body">
																<p>
																	- משקי בית שמעוניינים במשכנתא מסוימת.<br />
																	- בחינת היתכנות למחזורי משכנתא.
																</p>
															</div>
														</div>

													</div>
												</div>
												<div class="post__email">
													<span>eliran@optimai.co.il</span>
													<img src="/img/email-icon.png" alt="" />
												</div>
											</div>

										</div>
									</div>
									<div class="posts__item">
										<div class="post">
											<div onClick={() => openVideo('mkf7Ep5XmFc')} class="post__img" style={{ backgroundImage: "url('/img/opnew.gif')" }}>

											</div>
											<div class="post__content">
												<div class="post__title">

													אופטימאי - ראיון ברשת 13 <span>מחשבון משכנתא חכם</span>
												</div>
												<div class="post__text">
													מחשבון המסוגל לחזות בדיוק רב את רמת הסיכון שהלווה והעסקה מהווים לבנקים השונים.
												</div>

												<div class="post__spoller">
													<div class="spoller spoller--sm">
														<div class="spoller__item">
															<div class="spoller__head ">
																<div class="spoller__title">נסיון</div>
															</div>
															<div class="spoller__body ">
																<p>
																	מתן תחזיות משכנתא ליותר מרבע מליון משתמשים.
																</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">השכלה</div>
															</div>
															<div class="spoller__body">
																<p>אימון על אלפי לווים ומשכנתאות.</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">מומחיות</div>
															</div>
															<div class="spoller__body">
																<p>מתן תחזית ריביות ללוים על פי הפרופיל הפיננסי שלהם ושל העסקה.</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">מתאים ל</div>
															</div>
															<div class="spoller__body">
																<p>
																	- אנשים שמתלבטים אם לקחת משכנתא.<br />
																	- אנשים שהחליטו לקחת משכנתא.<br />
																	- אנשים שקיבלו הצעה למשכנתא.
																</p>
															</div>
														</div>

													</div>
												</div>
												<div class="post__email">
													<span>optimai@optimai.co.il</span>
													<img src="/img/email-icon.png" alt="" />
												</div>
											</div>

										</div>
									</div>
									<div class="posts__item">
										<div class="post">
											<div onClick={() => openVideo('CTeM0jBclj4')} class="post__img" style={{ backgroundImage: "url('/img/Tzilanew.gif')" }}>
												<div class="single-img">
													<img src="/img/brown-house.png" alt="" />
												</div>
											</div>
											<div class="post__content">
												<div class="post__title">
													צילה חורש - איך זה עובד <span>יועצת משכנתאות</span>
												</div>
												<div class="post__text">
													יועצת משכנתאות מוסמכת.<br />
													בעלת היכרות מעמיקה עם תנאי רגולציה של בנק ישראל.
												</div>

												<div class="post__spoller">
													<div class="spoller spoller--sm">
														<div class="spoller__item">
															<div class="spoller__head ">
																<div class="spoller__title">נסיון</div>
															</div>
															<div class="spoller__body ">
																<p>
																	בשנים האחרונות ייעצתי, ליוויתי ונתתי מענה ליותר מ 150 משקי בית.
																</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">השכלה</div>
															</div>
															<div class="spoller__body">
																<p>
																	- תואר ראשון מהאוניברסיטה הפתוחה במדעי החברה.<br />
																	- תואר שני ביישוב סכסוכים עם התמחות במשא ומתן וגישור.<br />
																	- קורס יעוץ משכנתאות.
																</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">מומחיות</div>
															</div>
															<div class="spoller__body">
																<p>מכרזי ריביות מול הבנקים.</p>
															</div>
														</div>
														<div class="spoller__item">
															<div class="spoller__head">
																<div class="spoller__title">מתאימה ל</div>
															</div>
															<div class="spoller__body">
																<p>משקי בית בתהליך לקיחת משכנתא.</p>
															</div>
														</div>

													</div>
												</div>
												<div class="post__email">
													<span>tzila@optimai.co.il</span>
													<img src="/img/email-icon.png" alt="" />
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</section>  */}
						{/*
						<section class="section services-section">
							<div class="container">
								<div class="section__head">
									<h2 class="section__title">השירותים של אופטימאי</h2>
									<h3 class="section__subtitle">דרך קלה פשוטה וחכמה לקחת משכנתא</h3>
								</div>

								<div class="services">
									<div class="services__item services__item--free">
										<div class="services__head">
											<div class="services__icon">
												<img src="/img/price-phone-image-desktop.png" alt="" />
											</div>
											<div class="services__title"><strong>בדיקת</strong> היתכנות</div>
										</div>
										<div class="services__body">
											<ul class="services__list">
												<li> שיחת הכרות</li>
												<li> מיפוי ראשוני</li>
												<li> המלצות לפעולות נדרשות</li>
											</ul>
										</div>
										<div class="services__foot">
											<div class="services__price">ללא עלות</div>
											<div class="services__btn">
												<a onClick={() => setPhonePopup(true)} class="btn btn--sm btn--info"><span class="btn__inner">התקשר</span></a>
											</div>
										</div>
									</div>
									<div class="services__item">
										<div class="services__head">
											<div class="services__icon">
												<img src="/img/price-paper-desktop.png" alt="" />
											</div>
											<div class="services__title"><strong>תכנון</strong> משכנתא </div>
										</div>
										<div class="services__body">
											<ul class="services__list">
												<li> מיפוי פיננסי</li>
												<li> תכנון משכנתא</li>
												<li> תחזית ריביות</li>
												<li> המלצות לפעולות נדרשות</li>
												<li> דוח מפורט</li>
											</ul>
										</div>
										<div class="services__foot">
											<div class="services__price">מחיר 1000 ₪</div>
											<div class="services__txt">כולל מע"מ</div>
											<div class="services__btn">
												<a href="https://secure.cardcom.solutions/e/Uvrr" class="btn btn--sm"><span class="btn__inner">לרכישה</span></a>
											</div>
										</div>
									</div>
									<div class="services__item">
										<div class="services__head">
											<div class="services__icon">
												<img src="/img/price-houses-desktop.png" alt="" />
											</div>
											<div class="services__title"><strong>מיחזור</strong> משכנתא</div>
										</div>
										<div class="services__body">
											<ul class="services__list">
												<li> מיפוי פיננסי</li>
												<li> תכנון משכנתא</li>
												<li> תחזית ריביות</li>
												<li> מכרז ריביות מול הבנקים</li>
												<li> ליווי עד החתימה</li>
											</ul>
										</div>
										<div class="services__foot">
											<div class="services__price">מחיר 3500 ₪</div>
											<div class="services__txt">כולל מע"מ</div>
											<div class="services__btn">
												<a href="https://secure.cardcom.solutions/e/Uvro" class="btn btn--sm"><span class="btn__inner">לרכישה</span></a>
											</div>
										</div>
									</div>
									<div class="services__item">
										<div class="services__head">
											<div class="services__icon">
												<img src="/img/price-single-house-desktop.png" alt="" />
											</div>
											<div class="services__title">משכנתא <strong>חדשה</strong></div>
										</div>
										<div class="services__body">
											<ul class="services__list">
												<li> מיפוי פיננסי</li>
												<li> תכנון משכנתא</li>
												<li> תחזית ריביות</li>
												<li> מכרז ריביות מול הבנקים</li>
												<li> ליווי עד החתימה</li>
											</ul>
										</div>
										<div class="services__foot">
											<div class="services__price">מחיר 3950 ₪</div>
											<div class="services__txt">כולל מע"מ</div>
											<div class="services__btn">
												<a href="https://secure.cardcom.solutions/e/Uvru" class="btn btn--sm"><span class="btn__inner">לרכישה</span></a>
											</div>
										</div>
									</div>
								</div>
							</div>

						</section>
					*/}
						<section class="gallery-section section">
							<div class="container">
								<div class="section__head">
									<h2 class="section__title"><strong>אופטימאי</strong> בתקשורת</h2>
								</div>
								<div class="gallery-slider-wrapper">
									<div class="gallery-slider-button prev" onClick={() => SliderPrev()}></div>
									<div class="gallery-slider" id="gallery-slider">
										{sliderItems.map((slide, index) => (
											<div class="gallery-slider__item" id={"gallery-slider__item-" + (index + 1)}>
												<a onClick={() => openVideo(slide.link)} class="gallery-img">
													<img src={"/img/" + slide.img} alt="" />
													<span class="play-button"></span>
												</a>
											</div>
										))}
									</div>
									<div class="gallery-slider-button next" onClick={() => SliderNext()}></div>
								</div>

								<div class="gallery-slider__pagination slider-pagination" SetItemNum>
									{sliderPoints.map((item, index) => (
										<div onClick={() => SetItemNum(index + 1)} class={currSlide === (index + 1) ? 'slider-bullets slider-bullets--active' : 'slider-bullets'}></div>
									))}
								</div>
							</div>
						</section>
							
						{/* <section class="form-section section">
							<div class="form-slider-wrapper">
								<div class="form-slider">
									<div class="form-slider-text">למשכנתא שחישבת<span>השאר פרטים</span></div>
									<input type="range" min="2" max="60" id="calc-mashkanta" class="form-slider-input" disabled />
								</div>
							</div>
							<div class="form-wrapper">
								<div class="form-send-successful">
									<span>פרטיך נשלחו בהצלחה<br />אנחנו נחזור אליך</span>
								</div>
								<div class="form-title">
									<strong>השלם את הנתונים</strong>
									נציג מטעם אופטימאי ייעוץ משכנתא חכם יחזור אליך
								</div>
								<div class="form-inputs-wrapper">
									<div class="form-input-wrapper inline-block">
										<div class="from-input-warning">חובה לסמן!</div>
										<input onChange={() => readInputValue('firstName')} type="text" placeholder="שם פרטי" id="firstName" />
									</div>
									<div class="form-input-wrapper inline-block">
										<div class="from-input-warning">חובה לסמן!</div>
										<input onChange={() => readInputValue('lastName')} type="text" placeholder="שם משפחה" id="lastName" />
									</div>
									<div class="form-input-wrapper inline-block">
										<div class="from-input-warning">חובה לסמן!</div>
										<div class="from-input-warning currValid">הזן מספר טלפון חוקי!</div>
										<input onChange={() => readInputValue('phone')} type="number" placeholder="טלפון נייד" id="phone" inputmode="numeric" />
									</div>
									<div class="form-input-wrapper inline-block">
										<div class="from-input-warning">חובה לסמן!</div>
										<div class="from-input-warning currValid">הזן כתובת אמייל חוקית!</div>
										<input onChange={() => readInputValue('email')} type="text" placeholder="דואר אלקטרוני" id="email" />
									</div>
								</div>
								<div class="form-questions-wrapper">
									<div class="form-questions-item inline-block">
										<div class="form-tip">
											<img class="inline-block" src="/img/tip-icon.png" alt="" />
											<div class="form-tip-text inline-block">
												לאיזה צורך השתמשת במחשבון?
												<span>טיפ: אם בכוונתך לקחת משכנתא ב 24 החודשים הקרובים, בחר "צריך משכנתא"</span>
											</div>
										</div>
									</div>
									<div class="form-questions-item inline-block send">
										<div class="form-radio-wrapper inline-block">
											<div class="from-input-warning">חובה לסמן!</div>
											<input class="inline-block" onChange={(event) => radioChange(event)} type="radio" name="mortgage" value="need" id="mortagageNeed" />
											<label class="inline-block" for="need">צריך משכנתא</label><br />
											<input class="inline-block" onChange={(event) => radioChange(event)} type="radio" name="mortgage" value="interested" id="mortagageInterested" />
											<label class="inline-block" for="interested">רק מתעניין</label>
										</div>
										<a class="form-send-button inline-block" onClick={() => sendForm()}>שלח</a>
									</div>
									<div class="form-checkbox-wrapper">
										<div class="from-input-warning">חובה לסמן!</div>
										<input class="inline-block" type="checkbox" value="need" onChange={(event) => checkboxChange(event)} />
										<label class="inline-block" for="need">אני מאשר את תנאי השימוש באתר. ייתכן שנציג מטעמנו ייצור איתך קשר.*</label><br />
									</div>
								</div>
							</div>
						</section> */} 
					</main>
					{/* MAIN EOF */}

					{/* BEGIN FOOTER */}
					<footer class="footer">
						<div class="footer-image-wrapper">
							<div class="footer-image"></div>
						</div>
						<div class="container">
							<p>
								כל הזכויות שמורות לאופטימאי בע"מ | הגלישה באתר מהווה הסכמה <strong><a href="#" target="_blank">לתנאי התקנון</a></strong>
							</p>
						</div>
					</footer>
					{/* FOOTER EOF   */}

				</div>
				{/* main wrapper EOF   */}

			</body>
		</>
	);
};
