import { isEmptyArray } from 'formik';
import * as React from 'react';
import { ActiveSelectInput } from '../../components/optimaiComponents/active-select-input';
// import '../css/style.css';

export const ServeyStepFour = ({ serveyObject, setServeyObject, setCurrentStep, currentStep }) => {
  const [questionTwo, setQuestionTwo] = React.useState(false);
  const [questionThree, setQuestionThree] = React.useState(false);
  const [nextButton, setNextButton] = React.useState(false);
  const [answeredQuestions, setAnsweredQuestions] = React.useState(0);
  const [localServeyObject, setLocalServeyObject] = React.useState(0);
  const [localOsh, setLocalOsh] = React.useState(0);
  const oshList = [
    { name: 'יתרת זכות בלבד', value: '1' },
    { name: 'משיכת יתר', value: '2' },
    { name: 'חריגה ממסגרת', value: '3' }
  ];
  const [localChecks, setLocalChecks] = React.useState(0);
  const checksList = [
    { name: 'אין', value: '1' },
    { name: 'יש', value: '2' },
  ];
  const [localLates, setLocalLates] = React.useState(0);
  const latesList = [
    { name: 'אין', value: '1' },
    { name: 'יש', value: '2' },
  ];

  const saveServeyResult = () => {
    let newServeyObject = {
      ...serveyObject,
      ...{
        osh: localOsh,
        checks: localChecks,
        lates: localLates
      }
    };
    setServeyObject(newServeyObject);
    localStorage.setItem('serveyObject', JSON.stringify(newServeyObject));
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    let newServeyObject = {
      ...serveyObject,
      ...{
        osh: localOsh,
        checks: localChecks,
        lates: localLates
      }
    };
    setServeyObject(newServeyObject);
    localStorage.setItem('serveyObject', JSON.stringify(newServeyObject));
    setCurrentStep(currentStep - 1);
  };

  const IsEmpty = (value) => {
    const m = value === 0 || value === '' || value === null || value === undefined;
    return m;
  };

  const NotEmpty = (value) => {
    const m = value !== 0 && value !== '' && value !== null && value !== undefined;
    return m;
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0 });

    setLocalOsh(serveyObject['osh']);
    setLocalChecks(serveyObject['checks']);
    setLocalLates(serveyObject['lates']);

    if (NotEmpty(serveyObject['osh'])) {
      const osh = document.querySelector("#osh .selectbox__text");
      osh.innerHTML = serveyObject['osh'].name;
    }

    if (NotEmpty(serveyObject['checks'])) {
      const checks = document.querySelector("#checks .selectbox__text");
      checks.innerHTML = serveyObject['checks'].name;
    }

    if (NotEmpty(serveyObject['checks'])) {
      const lates = document.querySelector("#lates .selectbox__text");
      lates.innerHTML = serveyObject['lates'].name;
    }
  }, []);

  const readInputValue = (identificator) => {
    const localServeyObjectSave = {};
    const inputValue = document.getElementById(identificator).value;
    localServeyObjectSave[identificator] = inputValue;
    let newLocalServeyObject = {
      ...localServeyObject,
      ...localServeyObjectSave
    };
    setLocalServeyObject(newLocalServeyObject);
  };

  React.useEffect(() => {
    if (answeredQuestions === 'openQuestionTwo') {
      setQuestionTwo(true);
      setQuestionThree(false);
      setNextButton(false);
    } else if (answeredQuestions === 'openQuestionThree') {
      setQuestionTwo(true);
      setQuestionThree(true);
      setNextButton(false);
    } else if (answeredQuestions === 'openNextButton') {
      setQuestionTwo(true);
      setQuestionThree(true);
      setNextButton(true);
    }
  }, [answeredQuestions]);

  React.useEffect(() => {
    if (NotEmpty(localOsh) && answeredQuestions === 0) {
      setAnsweredQuestions('openQuestionTwo');
    }
    
    if (NotEmpty(localChecks) && NotEmpty(localOsh)) {
      setAnsweredQuestions('openQuestionThree');
    }
    
    if (NotEmpty(localLates) && NotEmpty(localChecks) && NotEmpty(localOsh)) {
      setAnsweredQuestions('openNextButton');
    }
  }, [localOsh, localChecks, localLates]);

  return (
    <>
      <div class="home-page__bg-1">
        <img src="img/home-page-bg-1.png" alt="" />
      </div>
      <div class="container">
        <div class="step">
          <div class="step__item">
            <div class="step__inner">
              4
            </div>
          </div>
          <div class="step__item">
            <div class="step__inner step__inner--active">
              3
            </div>
          </div>
          <div class="step__item ">
            <div class="step__inner step__inner--active">
              2
            </div>
          </div>
          <div class="step__item">
            <div class="step__inner step__inner--active">
              1
            </div>
          </div>
        </div>
        <div class="home-page__wrapper">
          <div class="home-page__form hp-form">
            <div class="hp-form__row">
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>פיגורים בהלוואות</span>
                </div>
                <div class="hp-form__field">
                  <ActiveSelectInput selectOptions={latesList} value={localLates} setValue={setLocalLates} disabled={!questionThree} selectId="lates" EmptyMassage="question-three-prev-empty" prevSelectId="checks" />
                  <div class="form-message none" Id="question-three-prev-empty">
                    <div class="form-message__text">חסר נתון קודם</div>
                    <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                  </div> 
                  {IsEmpty(localLates) && questionThree ? (
                    <div class="form-message">
                      <div class="form-message__text">
                      פיגרת בהחזר הלוואה בשנתיים האחרונות?
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : '' }
                  {NotEmpty(localLates) && questionThree ? (
                    <div class="form-message">
                      <div class="form-message__text">
                      עוד שלב אחד וסיימנו. לחץ על הבא.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  ) : '' }
                </div>
              </div>
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>ש'קים חוזרים</span>
                </div>
                <div class="hp-form__field">
                  <ActiveSelectInput selectOptions={checksList} value={localChecks} setValue={setLocalChecks} disabled={!questionTwo} selectId="checks" EmptyMassage="question-two-prev-empty" prevSelectId="osh" />
                  <div class="form-message none" Id="question-two-prev-empty">
                    <div class="form-message__text">חסר נתון קודם</div>
                    <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                  </div>
                  {IsEmpty(localChecks) && questionTwo ? (
                    <div class="form-message">
                      <div class="form-message__text">
                      האם חזר לך ש'ק בשנתיים האחרונות?
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : '' }
                  {NotEmpty(localChecks) && questionTwo ? (
                    <div class="form-message">
                      <div class="form-message__text">
                      קדימה, ממשיכים.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  ) : '' }
                </div>
              </div>
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>מצב חשבון</span>
                </div>
                <div class="hp-form__field">
                  <ActiveSelectInput selectOptions={oshList} value={localOsh} setValue={setLocalOsh} selectId="osh" />
                  {IsEmpty(localOsh) ? (
                    <div class="form-message">
                      <div class="form-message__text">
                      התייחס ל 3 החודשים האחרונים.
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : (
                    <div class="form-message">
                      <div class="form-message__text">
                      אוקיי, אפשר להמשיך.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="hp-form__buttons">
              <button type="button" class="button active" onClick={prevStep}>הקודם</button>
              <button type="button" class="button next-button" onClick={() => saveServeyResult()} disabled={!nextButton}>הבא</button>
            </div>
          </div>
        </div>
      </div>
      <div class="home-page__bg-2">
        <img src="img/home-page-bg-2.3.png" alt="" class="hidden-iphone" />
        <img src="img/home-page-bg-2.3-mobile.png" alt="" class="visible-iphone" />
      </div>
    </>
  );
};
