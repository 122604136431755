import * as React from 'react';
import Lottie from 'react-lottie';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Get, Post, Serialize } from '../services/http';

import buttonData from '../animations/source/calculator-button.json';
import { LinkCss } from '../services/cssService';
import { Header } from './header';
import { Footer } from './footer';

export const Team = () => {
	const [openVideoPopup, setOpenVideoPopup] = React.useState(false);
	const [videoLink, setVideoLink] = React.useState();

	React.useEffect(() => {
		LinkCss("/css/homepage-style.css");
	}, []);

	const openHowVideo = () => {
		let popup = document.getElementById("video-popup");
		let video = document.getElementById("optimai-video");

		video.play();
		popup.classList.add("active");
	};

	const closeVideoPopup = () => {
		let popup = document.getElementById("video-popup");
		let video = document.getElementById("optimai-video");

		video.pause();
		video.currentTime = 0;
		popup.classList.remove("active");
	};

	const openTab = (tabId) => {
		let clickedTab = document.getElementById(tabId);
		let tabs = document.querySelectorAll(".how-it-works-home-tabs__body.swiper");
		let tabstitles = document.querySelectorAll(".how-it-works-home-tabs__title");
		let tabTitle = document.getElementById(tabId + "-title");

		for (const tab of tabs) {
			tab.classList.remove("active");
		}

		clickedTab.classList.add("active");

		for (const tabstitle of tabstitles) {
			tabstitle.classList.remove("_tab-active");
		}

		tabTitle.classList.add("_tab-active");
	};

	const openVideo = (link) => {
		console.log("clicked");
		setVideoLink(link);
		setOpenVideoPopup(true);
	};

	const closeVideo = () => {
		console.log("clicked");
		setOpenVideoPopup(false);
	};

	const initAccordion = () => {
		const boxes = document.querySelectorAll('.spoller__head');

		console.log('boxes', boxes);

		for (const box of boxes) {
			box.addEventListener('click', function handleClick() {
				const parents = document.querySelectorAll('.spoller__item');
				const parent = box.closest(".spoller__item");

				if (parent.className === 'spoller__item active') {
					for (let i = 0; i < parents.length; i++) {
						parents[i].classList.remove('active');
					}
				} else {
					for (let i = 0; i < parents.length; i++) {
						parents[i].classList.remove('active');
					}
					parent.classList.add('active');
				}
			});
		}
	};

	React.useEffect(() => {
		initAccordion();
	}, []);

	return (
		<>
			<head>
				<title>Home</title>
				<meta charset="UTF-8" />
				<meta name="format-detection" content="telephone=no" />
				<link rel="shortcut icon" href="favicon.ico" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="description" content="" />
				<meta name="keywords" content="" />
			</head>

			<body>
 
				{openVideoPopup === true ? (
					<div id="popup-modal-container">
						<div id="modal-wrapper" class="modal-wrapper" onClick={() => closeVideo()}>
							<div class="close-popup">X</div>
							<iframe width="80%" height="630" src={'https://www.youtube.com/embed/' + videoLink + '?rel=0'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
					</div>
				) : ''}

				<div class="wrapper">
					<Header />
					<main class="home">
						<section class="team team-title">
							<div class="team__container team__container_big">
								<h2 class="section__title">
									נבחרת האופטימאים שלנו<br />
									צוות מומחים עם 50 שנות נסיון מצטבר
								</h2>
							</div>
						</section>

						<section class="team team-home">
							<div class="team__container team__container_big">
								<article class="posts-wrapper">
									<div class="posts">
										<div class="posts__item">
											<div class="post">
												<div onClick={() => openVideo('nrF5KVlzf9w')} class="post__img" style={{ backgroundImage: "url('/img/ofernew.gif')" }}>
													<div class="single-img">
														<img src="/img/red-tall-house.png" alt="" />
													</div>
												</div>
												<div class="post__content">
													<div class="post__title">
														רו"ח עופר אחדות - הבעיות בשוק המשכנתאות <span>יועץ פיננסי</span>
													</div>
													<div class="post__text">
														מומחה מימון ואשראי מנוסה.<br />
														כיהן במשך 13 שנים בתפקיד סמנכ"ל כספים ומוצר בחברת אשראי בורסאית וניהל אשראי בהיקפי ענק.<br />
														היכרות מעמיקה עם פתרונות אשראי בנקאיים וחוץ בנקאיים.
													</div>
													<div class="post__spoller">
														<div class="spoller spoller--sm">
															<div class="spoller__item">
																<div class="spoller__head ">
																	<div class="spoller__title">נסיון</div>
																</div>
																<div class="spoller__body ">
																	<p>
																		- למעלה מ 1,000 תיקי אשראי מסחריים.<br />
																		- למעלה מ 300 תיקי אשראי פרטיים.
																	</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">השכלה</div>
																</div>
																<div class="spoller__body">
																	<p>
																		- תואר ראשון (בהצטיינות) בכלכלה וחשבונואות.<br />
																		- רואה חשבון מוסמך.
																	</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">מומחיות</div>
																</div>
																<div class="spoller__body">
																	<p>יעוצים פיננסיים במגזר הפרטי והעסקי.</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">מתאים ל</div>
																</div>
																<div class="spoller__body">
																	<p>
																		- תכנון עסקאות.<br />
																		- תכנון מבנה אשראי.<br />
																		- אשראי לנדלן עסקי ומסחרי.
																	</p>
																</div>
															</div>

														</div>
													</div>
													<div class="post__email">
														<span>ofer@optimai.co.il</span>
														<img src="/img/email-icon.png" alt="" />
													</div>
												</div>

											</div>
										</div>
										<div class="posts__item">
											<div class="post">
												<div onClick={() => openVideo('mE_1uvDK-jc')} class="post__img" style={{ backgroundImage: "url('/img/elirannew.gif')" }}>
													<div class="single-img">
														<img src="/img/white-house-orange-top.png" alt="" />
													</div>
												</div>
												<div class="post__content">
													<div class="post__title">
														אלירן גנסיה - הפתרון של אופטימאי <span>מנהל פיתוח עיסקי</span>
													</div>
													<div class="post__text">

														עוסק בפיתוח כלים מתקדמים מבוססי בינה מלאכותית בתחום המשכנתאות והאשראי.<br />
														בעל היכרות מעמיקה עם תנאי רגולציה של בנק ישראל.

													</div>

													<div class="post__spoller">
														<div class="spoller spoller--sm">
															<div class="spoller__item">
																<div class="spoller__head ">
																	<div class="spoller__title">נסיון</div>
																</div>
																<div class="spoller__body ">
																	<p>
																		בשנים האחרונות ייעצתי, ליוויתי ונתתי מענה ליותר מ 600 משקי בית.
																	</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">השכלה</div>
																</div>
																<div class="spoller__body">
																	<p>
																		- מהנדס תוכנה מטעם הטכניון.<br />
																		- מומחה מערכות ניתוח ריביות ותחזיות פיננסיות.
																	</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">מומחיות</div>
																</div>
																<div class="spoller__body">
																	<p>ניתוח רמות סיכון תחזיות ריביות ותנאי אשראי.</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">מתאים ל</div>
																</div>
																<div class="spoller__body">
																	<p>
																		- משקי בית שמעוניינים במשכנתא מסוימת.<br />
																		- בחינת היתכנות למחזורי משכנתא.
																	</p>
																</div>
															</div>

														</div>
													</div>
													<div class="post__email">
														<span>eliran@optimai.co.il</span>
														<img src="/img/email-icon.png" alt="" />
													</div>
												</div>

											</div>
										</div>
										<div class="posts__item">
											<div class="post">
												<div onClick={() => openVideo('mkf7Ep5XmFc')} class="post__img" style={{ backgroundImage: "url('/img/opnew.gif')" }}>

												</div>
												<div class="post__content">
													<div class="post__title">

														אופטימאי - ראיון ברשת 13 <span>מחשבון משכנתא חכם</span>
													</div>
													<div class="post__text">
														מחשבון המסוגל לחזות בדיוק רב את רמת הסיכון שהלווה והעסקה מהווים לבנקים השונים.
													</div>

													<div class="post__spoller">
														<div class="spoller spoller--sm">
															<div class="spoller__item">
																<div class="spoller__head ">
																	<div class="spoller__title">נסיון</div>
																</div>
																<div class="spoller__body ">
																	<p>
																		מתן תחזיות משכנתא ליותר מרבע מליון משתמשים.
																	</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">השכלה</div>
																</div>
																<div class="spoller__body">
																	<p>אימון על אלפי לווים ומשכנתאות.</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">מומחיות</div>
																</div>
																<div class="spoller__body">
																	<p>מתן תחזית ריביות ללוים על פי הפרופיל הפיננסי שלהם ושל העסקה.</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">מתאים ל</div>
																</div>
																<div class="spoller__body">
																	<p>
																		- אנשים שמתלבטים אם לקחת משכנתא.<br />
																		- אנשים שהחליטו לקחת משכנתא.<br />
																		- אנשים שקיבלו הצעה למשכנתא.
																	</p>
																</div>
															</div>

														</div>
													</div>
													<div class="post__email">
														<span>optimai@optimai.co.il</span>
														<img src="/img/email-icon.png" alt="" />
													</div>
												</div>

											</div>
										</div>
										<div class="posts__item">
											<div class="post">
												<div onClick={() => openVideo('CTeM0jBclj4')} class="post__img" style={{ backgroundImage: "url('/img/Tzilanew.gif')" }}>
													<div class="single-img">
														<img src="/img/brown-house.png" alt="" />
													</div>
												</div>
												<div class="post__content">
													<div class="post__title">
														צילה חורש - איך זה עובד <span>יועצת משכנתאות</span>
													</div>
													<div class="post__text">
														יועצת משכנתאות מוסמכת.<br />
														בעלת היכרות מעמיקה עם תנאי רגולציה של בנק ישראל.
													</div>

													<div class="post__spoller">
														<div class="spoller spoller--sm">
															<div class="spoller__item">
																<div class="spoller__head ">
																	<div class="spoller__title">נסיון</div>
																</div>
																<div class="spoller__body ">
																	<p>
																		בשנים האחרונות ייעצתי, ליוויתי ונתתי מענה ליותר מ 150 משקי בית.
																	</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">השכלה</div>
																</div>
																<div class="spoller__body">
																	<p>
																		- תואר ראשון מהאוניברסיטה הפתוחה במדעי החברה.<br />
																		- תואר שני ביישוב סכסוכים עם התמחות במשא ומתן וגישור.<br />
																		- קורס יעוץ משכנתאות.
																	</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">מומחיות</div>
																</div>
																<div class="spoller__body">
																	<p>מכרזי ריביות מול הבנקים.</p>
																</div>
															</div>
															<div class="spoller__item">
																<div class="spoller__head">
																	<div class="spoller__title">מתאימה ל</div>
																</div>
																<div class="spoller__body">
																	<p>משקי בית בתהליך לקיחת משכנתא.</p>
																</div>
															</div>

														</div>
													</div>
													<div class="post__email">
														<span>tzila@optimai.co.il</span>
														<img src="/img/email-icon.png" alt="" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</article>
							</div>
						</section>

					</main>
					<Footer />
				</div>
			</body>
		</>
	);
};
